import { useAuth } from '@/components/context/AuthProvider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useProjectLazyQuery, useProjectsQuery } from '@/graphql'
import { createdProjectCategory, createdProjectId } from '@/helpers/globalVariables'
import { websiteRoutes } from '@/router/routes'
import { Form, message, Row, Space, Spin } from 'antd'
import { FC, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const AssignProject: FC = () => {
  const navigate = useNavigate()
  const { dashboard } = websiteRoutes
  const { pathname } = useLocation()
  const [, setEscLink] = useLocalStorage('esc-link')
  const [, setProjectId] = useLocalStorage(createdProjectId)
  const [, setProjectCategory] = useLocalStorage(createdProjectCategory)

  const { setModalType, setIsModalOpen } = useGeneralContext()

  const { user } = useAuth()
  const userId = user?.userProfile?.data?.id
  const [isDisabled, setIsDisabled] = useState(true)
  const [selectedProjectId, setSelectedProjectId] = useState<Maybe<string>>('')
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<Maybe<EnumProjectCategory>>('outgoing')

  const { data, loading } = useProjectsQuery({
    variables: { filter: { owner: { id: { eq: userId } } } },
    fetchPolicy: globalFetchPolicy,
  })
  const [getProject] = useProjectLazyQuery()
  const projectsData = data?.projects?.data as ProjectEntity[]
  const selectOptions = useMemo(() => {
    return projectsData
      ?.filter(it => !it?.attributes?.applications?.data?.length)
      .map((it: ProjectEntity) => {
        return {
          label: it?.attributes?.title,
          value: it?.id,
        }
      })
  }, [projectsData]) as SelectOptionsType[]

  const onConfirm = () => {
    setEscLink(pathname)
    setModalType('selected-project-info')
    setProjectId(selectedProjectId)
    setProjectCategory(selectedProjectCategory)
  }
  const onCreateProject = () => {
    setEscLink(pathname)
    navigate(`${dashboard.createApplication}/application-details/1`)
    setIsModalOpen(false)
  }
  const onChange = async (value: string) => {
    setSelectedProjectId(value)
    await getProject({
      fetchPolicy: globalFetchPolicy,
      variables: {id: value},
      onCompleted: (res) => {
        const project = res?.project?.data as ProjectEntity
        console.log(project)
        if (project) {
          setIsDisabled(false)
          setSelectedProjectId(project.id)
          setSelectedProjectCategory(project?.attributes?.category)
        }
      },
      onError: (error)=>{message.error(error.message)}
    })
  }
  return (
    <>
      <Title level={3}>{selectOptions?.length ? 'Select/Create Project' : 'Create Project'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {'First let’s create a project using a name familiar to you. All applications that are generated will then be filtered under this project for ease of navigation and reference. \n' +
          'No obligation – nothing will be submitted or charged until you accept our quote'
        }
      </Paragraph>
      {selectOptions?.length ?
        <Paragraph size={'sm'}
                   color={'gray'}>{'If you have an existing project set up you can select it below, alternatively please create a new one.'}</Paragraph>
        : null}
      {loading ? (
        <Spin />
      ) : (
        <>
          {selectOptions?.length ? (
            <Form layout={'vertical'} className={'form-content'}>
              <FormItem
                notFoundText={'No empty projects...'}
                name={'project'}
                type={'select'}
                label={'Project'}
                placeholder={'Select the project'}
                selectOptions={selectOptions}
                handleSelectChange={onChange}
              />
            </Form>
          ) : null}
        </>
      )}
      <Row justify={'end'} style={{ paddingTop: 24 }}>
        <Space>
          <Button text={'Create project'} btnType={'ghost'} onClick={onCreateProject} />
          {selectOptions?.length ? (
            <Button text={'Confirm selection'} btnType={'primary'} onClick={onConfirm} disabled={isDisabled} />
          ) : null}
        </Space>
      </Row>
    </>
  )
}
export default AssignProject
