export const getFileSizeFromUrl = async (url: string): Promise<number | null> => {
  try {
    const response = await fetch(url, { method: 'HEAD' }); // Use HEAD to avoid downloading the file
    if (!response.ok) {
      console.error(`Failed to fetch the file. HTTP status: ${response.status}`);
      return null;
    }

    const contentLength = response.headers.get('Content-Length');
    if (contentLength) {
      const fileSizeInBytes = parseInt(contentLength, 10);
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to megabytes
      return parseFloat(fileSizeInMB.toFixed(2)); // Round to 2 decimal places
    }

    console.error('Content-Length header is missing.');
    return null;
  } catch (error) {
    console.error('Error fetching file size:', error);
    return null;
  }
};