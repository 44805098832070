import GetFreeQuoteButton from '@/components/website/get-free-quote-button/GetFreeQuoteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import SectionLayout from '@/pages/website-landing/components/section-layout/SectionLayout'
import { FC } from 'react'

import './ShortSection.less'

import { Col, Row, Space } from 'antd'
import { Link } from 'react-router-dom'

const { Title, Paragraph } = WebsiteTypography

export type ShortSectionContent = {
  titleRegular: string,
  titleBold: string,
  paragraphFirst: string,
  paragraphSecond?: string,
  imgContent?: string,
  showImageBackground?: boolean,
  bgImageUrl?: string,
}

type Props = {
  content: ShortSectionContent
}

const ShortSection: FC<Props> = ({ content }) => {

  return (
   <SectionLayout showImageBackground={content.showImageBackground} bgImageUrl={content.bgImageUrl}>

     <Row className={'short-section-content'} justify={'space-between'}>
       <Col span={11}>
         <Title level={2} className={'section-title'} style={{ paddingBottom: '24px' }}>
           <span className={'w300'}>{content.titleRegular}</span>
           <span className={'bold'}>{content.titleBold}</span>
         </Title>

         <Space size={'large'} direction={'vertical'}>
           <Paragraph color={'website-secondary'}>
             {content.paragraphFirst}
           </Paragraph>

           <Paragraph color={'website-secondary'}
                      style={{ paddingBottom: '16px' }}>
             {content.paragraphSecond}
           </Paragraph>

           <GetFreeQuoteButton type={'orange'} />

         </Space>

       </Col>

       {content.imgContent && (
         <Col className={'short-section-img-wrapper'} span={11}>
           <img src={content.imgContent} alt={'image for short-section'} />
         </Col>
       )}

     </Row>

   </SectionLayout>
  )
}

export default ShortSection