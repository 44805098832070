import { useCompanyNamesQuery } from '@/graphql';
import { useMemo } from 'react';
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { SelectOptionsType } from '@/components/form-item/FormItem'
import { useAuth } from '@/components/context/AuthProvider'

interface UseCompanyOptionsHook {
  companyOptions: SelectOptionsType[];
  loading: boolean;
}

export type ModeType = |
  'all'|
  'service_provider' |
  'organization' |
  'organization-client' |
  'client' |
  'municipalities' |
  'agent' |
  'approvals' |
  'utility_services' |
  'railway_lines' |
  'provincial_roads' |
  'parks' |
  'national_roads' |
  'municipality' |
  'bridges'

  const useCompanyOptions = (mode: ModeType  = 'all'): UseCompanyOptionsHook => {
  const {user} = useAuth()
  const userOrganizationId = user?.organization?.data?.id
  let filters: CompanyFiltersInput
  switch (mode) {
    case 'all': filters = {}
      break
    case 'utility_services': filters = {applicationType: {slug: {eq: 'utility_services'}}}
      break
    case 'railway_lines': filters = {applicationType: {slug: {eq: 'railway_lines'}}}
      break
    case 'provincial_roads': filters = {applicationType: {slug: {eq: 'provincial_roads'}}}
      break
    case 'parks': filters = {applicationType: {slug: {eq: 'parks'}}}
      break
    case 'national_roads': filters = {applicationType: {slug: {eq: 'national_roads'}}}
      break
    case 'municipality': filters = {applicationType: {slug: {eq: 'municipality'}}}
      break
    case 'bridges': filters = {applicationType: {slug: {eq: 'bridges'}}}
      break
    case 'approvals': filters = {companyType: {in: ['service_provider', 'government', 'professional']}}
      break
    case 'service_provider': filters = {companyType: {eq: 'service_provider'}}
      break
    case 'organization': filters = {organization: {id: {eq: userOrganizationId}}}
      break
    case 'agent': filters = {and: [
        {companyType: {in: ['agent', 'service_provider']}},
        {organization: {id: {eq: userOrganizationId}}},
      ] }
      break
    case 'client': filters = {
      organization: {id: {eq: userOrganizationId}},
      companyType: {eq: 'client'}
    }
      break
    case 'municipalities': filters = {applicationType: {slug: {eq: 'municipality'}}}
      break
    default: filters = {}
  }
  const { data: companiesDataResponse, loading } = useCompanyNamesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: filters,
      pagination: { limit: -1 }
    },
  });

  const companyOptions = useMemo(() => {
    const companiesData = companiesDataResponse?.companies?.data as CompanyEntity[];
    return companiesData?.map((it) => ({
      label: it?.attributes?.name as string,
      value: it?.id as string,
    })) || [{ label: '', value: '' }];
  }, [companiesDataResponse]);

  return {
    companyOptions,
    loading,
  };
};

export default useCompanyOptions;
