import { SelectOptionsType } from '@/components/form-item/FormItem'
import { capitalize } from 'lodash'
import { removeUnderscores } from '@/helpers/removeUnderscores'

const userProfileOptions: EnumUserprofileProfiletype[] = ['client', 'agent', 'contractor', 'government', 'service_provider', 'engineer', 'external_agent']
export const getUserProfileRoleOptions = (): SelectOptionsType[] => {
  return userProfileOptions?.map(it => ({
    label: removeUnderscores(it),
    value: it,
  })) as SelectOptionsType[]
}

