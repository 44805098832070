import BlackNotification from '@/components/blackNotification/BlackNotification'
import FormItem from '@/components/form-item/FormItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useUpdateApplicationMutation } from '@/graphql'
import { approvalsProgressCalculator } from '@/helpers/approvalsProgressCalculator'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useApprovalSetCopy from '@/hooks/useApprovalSetCopy'
import useCompanyOptions, { ModeType } from '@/hooks/useCompaniesOptions'
import { Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const AddApprovalItem: FC = () => {
  const [form] = useForm()
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity
  const approvalSet = appData?.attributes?.approvalSet as ComponentDataApplicationServiceProvider[]
  const approvalSetCopy = useApprovalSetCopy(approvalSet)
  const { setIsModalOpen } = useGeneralContext()

  const {data, loading} = useApplicationByUuid()
  const currentApplication = data?.applications?.data?.[0] as ApplicationEntity
  const currentApplicationType = currentApplication?.attributes?.applicationType?.data?.attributes?.slug as ModeType

  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions(currentApplicationType)


  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    form.submit()
  }
  const handleSelectChange = () => {
    setIsConfirmDisabled(false)
  }
  const onFinish = async (values: { company: string }) => {

    const newApprovalItem = {
      company: values?.company,
    } as ComponentDataApplicationServiceProviderInput
    const newApprovalSet = [newApprovalItem, ...approvalSetCopy || []]
    const newProgress = approvalsProgressCalculator(newApprovalSet)

    appData?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: appData?.id,
        data: {
          approvalSet: newApprovalSet,
          progress: newProgress,
        },
      },
      onCompleted: () => {
        BlackNotification('New approval item created')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error while trying to create approval item. ${error.message}`)
      },
      refetchQueries: 'active',
    })
  }

  return (
    <>
      <Title level={3}>
        {'Add approval item'}
      </Title>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`Please, select a Company in the form below to add new approval item to a current application.`}
      </Paragraph>
      <Form layout={'vertical'} onFinish={onFinish} form={form}>
        <FormItem
          name={'company'}
          label={'Company'}
          type={'select'}
          selectOptions={companyOptions}
          placeholder={'Select Company'}
          loading={companiesDataLoading || loading}
          showSearch={true}
          handleSelectChange={handleSelectChange}

        />
      </Form>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
        />
        <Button
          text={'Confirm'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={applicationUpdating || appDataLoading}
          disabled={isConfirmDisabled}
        />
      </Row>
    </>
  )
}
export default AddApprovalItem
