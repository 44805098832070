import { useAuth } from '@/components/context/AuthProvider'
import { useGeneralContext } from '@/components/context/GeneralContext'
import DoubleDotsMenu from '@/components/double-dots-menu/DoubleDotsMenu'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import Status from '@/components/status/Status'
import Typography from '@/components/typography/Typography'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import { Col, Row, Skeleton } from 'antd'
import classnames from 'classnames'
import { FC, useEffect, useState } from 'react'
import './AgentCard.less'


const { Text } = Typography
type Props = {
  userData: UserProfileEntity | undefined
  isSelected?: boolean
  onCardClick?: (id: string) => void
  isSelectable?: boolean
  onDelete?: (id: string) => void
  infoItem?: ComponentDataAgentInfo
  loading: boolean
}
const AgentCard: FC<Props> = ({ loading, infoItem, onDelete, userData, isSelected, onCardClick, isSelectable = false }) => {
  const { user, organization } = useAuth()
  const [isCardOwner, setIsCardOwner] = useState(false)
  const { setIsModalOpen, setModalType, setCurrentId } = useGeneralContext()
  const userFullName = userData?.attributes?.fullName || ''
  const primaryEmail = userData?.attributes?.primaryEmail || ''
  const avatarImageUrl = userData?.attributes?.avatar?.data?.attributes?.url || ''

  const source: 'internal' | 'external' = userData?.attributes?.organization?.data?.attributes?.slug === organization ? 'internal' : 'external'

  useEffect(() => {
    if (user?.userProfile?.data?.id === userData?.id) {
      setIsCardOwner(true)
    } else {
      setIsCardOwner(false)
    }
  }, [user?.userProfile?.data?.id, userData?.id])

  const cardClasses = classnames({
    'agent-card': true,
    'active': isSelected,
    'isSelectable': isSelectable,
  })
  const radioClasses = classnames({
    'agent-card-radio': true,
    'active': isSelected,
  })

  const handleCardClick = () => {
    (isSelectable && onCardClick) ? onCardClick(userData?.id || '') : ''
  }

  const menuItems = [
    {
      label: 'Unassign agent',
      link: '#',
      onClick: () => {
        onDelete && onDelete(userData?.id as string)
      },
    },
    {
      label: 'Send notification',
      link: '#',
      onClick: () => {
        setIsModalOpen(true)
        setModalType('agent-assign-notification')
        setCurrentId(userData?.id || '')
      },
    },
    {
      label: 'Change status',
      link: '#',
      disabled: !isCardOwner,
      onClick: () => {
        setIsModalOpen(true)
        setModalType('change-agent-status')
        setCurrentId(infoItem?.agentProfile?.data?.id || '')
      },
    }
  ]

  return loading? <Skeleton avatar={true} /> : (
    <div
      className={cardClasses}
      onClick={() => handleCardClick()}
    >
      {/*<UserAvatar avatarImageUrl={avatarImageUrl} userFullName={userFullName} />*/}
        <Row style={{ width: '100%' }}>
          <Col xs={12} style={{paddingRight: 8}}>
            <SelectUserItem
              name={userFullName}
              email={primaryEmail}
              avatar={avatarImageUrl} />
          </Col>
          <Col xs={6}>
            <div className={'info-item'}>
              <Text weight={'w700'}>{userData?.attributes?.company?.data?.attributes?.name}</Text>
            </div>
          </Col>
          <Col span={5}>
            <Text weight={'w700'} color={source === 'internal' ? 'blue' : 'orange'}>{source}</Text>
          </Col>
        </Row>
      <div className={'agent-menu'}>
        {isSelectable ? <div className={radioClasses} /> : (
          <>
            <Status status={infoItem?.assignStatus as EnumComponentdataagentinfoAssignstatus} />
            <div style={{ paddingLeft: 12 }}>
              <DoubleDotsMenu menuItems={menuItems} />
            </div>
          </>
        )
        }
      </div>
    </div>
  )
}

export default AgentCard
