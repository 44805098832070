import Button from '@/components/button/Button'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import GeocoderControl from '@/components/google-map/geocoder-control/geocoder-control'
import { ValidationResponse } from '@/components/google-map/helpers/validateProjectSize'
import IsLoadingMapCover from '@/components/google-map/is-loading-map-cover/IsLoadingMapCover'
import BLACK_WHITE_STYLE from '@/components/google-map/map-styles/basic-map-style.json'
import { mapInitialViewSettings, maxBounds, maxZoom, minZoom } from '@/components/google-map/mapInitialSettings'
import { SvgIcon } from '@/components/icon'
import SwitchFormItem from '@/components/switch-form-item/SwitchFormItem'
import { containerStyle } from '@/pages/manager-map/helpers/mapVariables'
import MapboxDraw, { DrawCreateEvent } from '@mapbox/mapbox-gl-draw'
import { Popover, Row, Space, Tooltip } from 'antd'
import classnames from 'classnames'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { LngLatBoundsLike } from 'mapbox-gl'
import { FC, LegacyRef, MutableRefObject, PropsWithChildren, Ref, useEffect, useState } from 'react'
import Map, { FullscreenControl, MapRef, MapStyle, NavigationControl, ScaleControl, ViewState, ViewStateChangeEvent } from 'react-map-gl'
import { MapEvent } from 'react-map-gl/dist/es5/types'
import './MapBoxMap.less'
import pindrop from '../images/marker-06.png'
import Typography from '@/components/typography/Typography'

const { Text } = Typography

export type ProjectInfo = {
  points: number,
  length: number,
  area: number
}
type MapBoxMapProps = {
  isAreasCreating?: boolean
  isSaving?: boolean
  onDelete?: (e: DrawCreateEvent) => void
  onUpdate?: (e: DrawCreateEvent) => void
  mapRef: Ref<MapRef>
  mapWrapperRef?: MutableRefObject<undefined>
  isShowGeometry?: boolean
  isShowProvinces?: boolean
  setIsShowGeometry?: (e: boolean) => void
  setIsShowProvinces?: (e: boolean) => void
  onMapRefresh?: () => void
  isClearingAllDrawings?: boolean
  projectInfo?: ValidationResponse | null
  isDrawTools?: boolean
  isGeometryVisibilityToggle?: boolean
  isProvincesVisibilityToggle?: boolean
  setIsMapLoaded?: (isLoaded: boolean) => void
  setMapRef?: (mapRef: Ref<MapRef>) => void
  mapContainerHeight?: number
  onClick?: (event: any) => void
  onZoom?: (event: ViewStateChangeEvent) => void
  viewState?: ViewState
  onLoad?: () => void
  isSaveButtonShow?: boolean
  isSaveLoading?: boolean
  isSaveDisabled?: boolean
  onSave?: () => void
  handleGetSnapshot?: () => void
  handleZoomToProject?: () => void
  isGetSnapshotShow?: boolean
  isZoomToProjectShow?: boolean
  snapshotLoading?: boolean
  isShowControls?: boolean
  isShowSearch?: boolean
  isSettingsPanelVisible?: boolean
  isLoading?: boolean

}
const MapBoxMap: FC<PropsWithChildren & MapBoxMapProps> = ({
                                                             children,
                                                             isAreasCreating,
                                                             mapRef,
                                                             onDelete,
                                                             onUpdate,
                                                             isShowGeometry,
                                                             isShowProvinces,
                                                             setIsShowGeometry,
                                                             setIsShowProvinces,
                                                             onMapRefresh,
                                                             isClearingAllDrawings,
                                                             projectInfo,
                                                             isDrawTools = true,
                                                             isGeometryVisibilityToggle = true,
                                                             isProvincesVisibilityToggle = true,
                                                             mapWrapperRef,
                                                             setIsMapLoaded,
                                                             setMapRef,
                                                             mapContainerHeight = 800,
                                                             onClick,
                                                             onZoom,
                                                             onLoad,
                                                             isSaveButtonShow = false,
                                                             isSaveLoading,
                                                             isSaveDisabled,
                                                             onSave,
                                                             isGetSnapshotShow,
                                                             isZoomToProjectShow,
                                                             handleZoomToProject,
                                                             handleGetSnapshot,
                                                             snapshotLoading,
                                                             isSettingsPanelVisible = true,
  isLoading,
  isShowControls = true,
  isShowSearch = true,
                                                           }) => {
  const MAPBOX_TOKEN = import.meta.env.WEBSITE_MAPBOX_TOKEN as string
const {user, role} = useAuth()
  const [isSimpleStylesChecked, setIsSimpleStylesChecked] = useState(false)
  const [drawInstance, setDrawInstance] = useState<MapboxDraw | null>(null)
  const [drawings, setDrawings] = useState<FeatureCollection<Geometry, GeoJsonProperties> | Feature | null>(null)
  const [isSettingsActive, setIsSettingsActive] = useState(false)


  // TODO: Set initial feature in Application Review/Edit mode
  // useEffect(() => {
  //   setDrawings(initFeature as FeatureCollection<Geometry, GeoJsonProperties>)
  // }, [])

  useEffect(() => {
    if (isClearingAllDrawings) {
      drawInstance?.deleteAll()
    }
  }, [isClearingAllDrawings])

  const onStyleSwitchChange = (checked: boolean) => {
    setIsSimpleStylesChecked(checked)
  }
  const onGeometryVisibilityChange = (checked: boolean) => {
    if (setIsShowGeometry) {
      setIsShowGeometry(checked)
    }
  }
  const onProvincesVisibilityChange = (checked: boolean) => {
    if (setIsShowProvinces) {
      setIsShowProvinces(checked)
    }
  }
  const handleSave = () => {
    onSave && onSave()
    setDrawings(null)
  }

  const onMapLoad = async (e: MapEvent<any>) => {
    // setCurrentCanvas(e.target.getCanvas())

    onLoad && onLoad()
    setIsMapLoaded && setIsMapLoaded(true)
    // setMapInstance(e.target.getMap())
    setMapRef && setMapRef(e.target as Ref<MapRef>)

    e.target?.loadImage(pindrop, (error, image) => {
        if (error) throw error;
        !!image && e.target?.addImage('custom-marker-icon', image);
      }
    );

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: 'simple_select',
      controls: {
        point: true,
        line_string: true,
        polygon: true,
        trash: true,
      },
    })
    if (isClearingAllDrawings) {
      drawInstance?.deleteAll()
    }
    isDrawTools && e.target.addControl(draw)
    e.target.on('draw.create', (e: DrawCreateEvent) => {
      if (e.features?.[0]) {
        setDrawings(e.features?.[0] as Feature)
      }
      onUpdate && onUpdate(e)
    })
    e.target.on('draw.update', onUpdate)
    e.target.on('draw.delete', onDelete)


    drawings && draw.set(drawings as FeatureCollection)
    const uploadedDrawingsIds = isDrawTools && draw?.getAll().features?.map(it => it?.id)

    isDrawTools && setDrawInstance(draw)
    const drawControlContainer = e.target.getContainer().querySelectorAll('.mapboxgl-ctrl-group')[2]

    const refreshButton = document.createElement('button')
    refreshButton.className = 'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-refresh'
    refreshButton.addEventListener('click', () => {
      draw.deleteAll()
      onMapRefresh && onMapRefresh()
    })
    drawControlContainer?.appendChild(refreshButton)
  }
  const settingsPanelClasses = classnames({
    'map-settings-panel': true,
    'settings-panel-active': isSettingsActive
  })
  const onSettingsPanelExpand = () => {
    setIsSettingsActive(!isSettingsActive)
  }
  const settingsContent = () => (
    <div className={'filters-content'}>
      <Space direction={'vertical'} size={'middle'}>
        {isProvincesVisibilityToggle && (
          <Tooltip placement={'left'} title={isShowProvinces ? 'Hide provinces boundary' : 'Show provinces boundary'}>
            <SwitchFormItem onChange={onProvincesVisibilityChange} title={'Show provinces layer'} />
          </Tooltip>
        )}
        {isGeometryVisibilityToggle && (
          <Tooltip placement={'left'} title={isShowGeometry ? 'Hide geometry' : 'Show geometry'}>
            <SwitchFormItem onChange={onGeometryVisibilityChange} title={'Show geometry layer'} />
          </Tooltip>
        )}
        <Tooltip placement={'left'} title={isSimpleStylesChecked ? 'Default map styles' : 'Simplify map styles'}>
          <SwitchFormItem onChange={onStyleSwitchChange} title={'Simplify map styles'} />
        </Tooltip>
        <FormItemDivider marginBottom={0} marginTop={0} />
        {(role === 'service_provider' && user?.userProfile?.data?.attributes?.profileType === 'service_provider') &&
          <>
            <SwitchFormItem onChange={() => ''} title={'My projects'} />
            <SwitchFormItem onChange={() => ''} title={'Applications for approval'} />
          </>}
      </Space>
    </div>
  )

  return (
    <div className={'map-container'}>
        {/*<div className={'filters-content'}>*/}
        {/*  <Space direction={'vertical'} size={'middle'}>*/}
        {/*    {isGeometryVisibilityToggle && (*/}
        {/*      <Tooltip placement={'left'} title={isShowGeometry ? 'Hide geometry' : 'Show geometry'}>*/}
        {/*        <SwitchFormItem onChange={onGeometryVisibilityChange} title={'Show geometry layer'} />*/}
        {/*      </Tooltip>*/}
        {/*    )}*/}
        {/*    <Tooltip placement={'left'} title={isSimpleStylesChecked ? 'Default map styles' : 'Simplify map styles'}>*/}
        {/*      <SwitchFormItem onChange={onStyleSwitchChange} title={'Simplify map styles'} />*/}
        {/*    </Tooltip>*/}
        {/*    <FormItemDivider marginBottom={0} marginTop={0} />*/}
        {/*    {(role === 'service_provider' && user?.userProfile?.data?.attributes?.profileType === 'service_provider') &&*/}
        {/*      <>*/}
        {/*        <SwitchFormItem onChange={()=>''} title={'My projects'} />*/}
        {/*      <SwitchFormItem onChange={()=>''} title={'Applications for approval'} />*/}
        {/*    </>}*/}
        {/*  </Space>*/}
        {/*</div>*/}
      {isSettingsPanelVisible && (
        <Popover
          overlayClassName={'map-settings-panel-content'}
          showArrow={false}
          content={settingsContent} title={''}
          trigger={'click'}
          placement={'topLeft'}
          onOpenChange={()=> setIsSettingsActive(!isSettingsActive)}
        >
          <Button btnType={'ghost'} className={'map-settings-button'} size={'middle'} icon={<SvgIcon type={isSettingsActive ? 'expand-arrow-down' : 'expand-arrow-up'} />} >
            <Text size={'sm'}>{'Map filters/ settings'}</Text>
            {/*<SvgIcon type={'slider-settings'}  />*/}
          </Button>
        </Popover>
      )}
      <div
        id={'map-wrapper'}
        ref={mapWrapperRef as unknown as LegacyRef<HTMLDivElement>}
        style={{ height: mapContainerHeight }}
      >
        <Map
          ref={mapRef}
          mapStyle={(isSimpleStylesChecked ? BLACK_WHITE_STYLE : 'mapbox://styles/mapbox/streets-v11') as MapStyle}
          initialViewState={mapInitialViewSettings}
          mapboxAccessToken={MAPBOX_TOKEN}
          style={containerStyle}
          minZoom={minZoom}
          maxZoom={maxZoom}
          maxBounds={maxBounds as LngLatBoundsLike}
          onLoad={onMapLoad}
          onClick={onClick}
          onZoom={onZoom}
        >
          {isShowControls && (
            <div style={{ position: 'absolute', right: 0 }}>
              <FullscreenControl />
              <NavigationControl />
            </div>

          )}
          {isShowSearch && <GeocoderControl mapboxAccessToken={MAPBOX_TOKEN} position={'top-left'} />}
          <ScaleControl position={'top-left'}
                        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #8f8f8f', cursor: 'default' }}
                        maxWidth={200} />
          {children}
        </Map>
      </div>
      {projectInfo && (
        <div className={'project-info-panel'}>
          <Text size={'xs'}>{`Points: ${projectInfo?.points ? projectInfo?.points : '—'}`}</Text>
          <Text size={'xs'}>{`Project Length: ${projectInfo?.length ? projectInfo?.length + ' km' : '—'}`}</Text>
          <Text size={'xs'}>
            <span>{'Project Area:'}</span>
            {projectInfo?.area ? (
              <>
                <span>{` ${projectInfo?.area + ' km'}`}</span>
                <span>&#178;</span>
              </>
            ) : <span>{' —'}</span>
            }
          </Text>
          <Row align={'bottom'}>

          <Text size={'xs'} style={{paddingRight: 4}}>{`Status: `}</Text> <Text weight={'w700'} color={projectInfo?.isValid ? 'green' : 'error'}>{projectInfo?.isValid ? ' valid' : ' invalid'}</Text>
          </Row>
        </div>
      )}
      <div className={'map-btn-container'}>
        {isZoomToProjectShow && (
          <Button
            text={'Zoom to Project'} btnType={'ghost'}
            onClick={handleZoomToProject}
            style={{ marginRight: 10, borderColor: 'gray' }}
            size={'middle'}
          />
        )}
        {isGetSnapshotShow && (
          <Button text={'Get Snapshot'} onClick={handleGetSnapshot} loading={snapshotLoading}/>
        )}
        {isSaveButtonShow && (
          <Button
            text={'Save'}
            size={'middle'}
            loading={isSaveLoading}
            onClick={handleSave}
            disabled={isSaveDisabled}
          />
        )}
      </div>
    </div>
  )
}
export default MapBoxMap