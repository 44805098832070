import { sleep } from '@/helpers/sleeper';

type ScrollToSectionOptions = (sectionId: string, delay?: number) => Promise<void>

export const scrollToSection: ScrollToSectionOptions  = async (sectionId, delay = 200) => {
  await sleep(delay)
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};