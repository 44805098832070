export const USER_TOKEN = 'userToken'
export const getAuthToken = (): string | null => {
  return localStorage.getItem(USER_TOKEN)
}

export const setAuthToken = (token: string): void => {
  localStorage.setItem(USER_TOKEN, token)
}

export const removeAuthToken = (): void => {
  localStorage.removeItem(USER_TOKEN)
}
