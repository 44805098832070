import { Button as WrappedBtn } from 'antd'
import classnames from 'classnames'
import { CSSProperties, FC } from 'react'
import './WebsiteButton.less'

type ButtonProps = {
  btnType?: 'primary' | 'ghost-primary' | 'ghost-white' | 'icon' | 'text'
  style?: CSSProperties
}
type BtnProps = typeof WrappedBtn.defaultProps

const WebsiteButton: FC<BtnProps & ButtonProps> = ({
                                                     btnType = 'primary',
                                                     icon,
                                                     children,
                                                     ...props
                                                   }) => {
  const btnClasses = classnames({
    'primary': btnType === 'primary',
    'ghost-primary': btnType === 'ghost-primary',
    'ghost-white': btnType === 'ghost-white',
    'icon': btnType === 'icon',
    'text': btnType === 'text',
    [props?.className as string]: true,
  })

  return (
    <WrappedBtn prefixCls={'website-btn'} icon={icon} {...props} className={btnClasses}>
      {children ? children : null}
    </WrappedBtn>
  )
}

export default WebsiteButton
