import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import { SvgIcon } from '@/components/icon'
import ItemTag from '@/components/item-tag/ItemTag'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompanyQuery, useResubmitApplicationMutation, useUpdateApplicationMutation } from '@/graphql'
import { approvalsProgressCalculator } from '@/helpers/approvalsProgressCalculator'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useApprovalSetCopy from '@/hooks/useApprovalSetCopy'
import { Row, Skeleton } from 'antd'
import { FC } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Text } = Typography
const ResubmitApplication: FC = () => {
  const { user } = useAuth()
  const { setIsModalOpen, currentId } = useGeneralContext()

  const [resubmitApplication, { loading: resubmitLoading }] = useResubmitApplicationMutation()
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity
  const approvalSet = appData?.attributes?.approvalSet as ComponentDataApplicationServiceProvider[]

  const approvalSetCopy = useApprovalSetCopy(approvalSet)

  const { data, loading: companiesDataLoading } = useCompanyQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
    skip: currentId === '' || undefined,
  })
  const company = data?.company?.data as CompanyEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onConfirm = async () => {
    const changedApprovalSet = approvalSetCopy?.map((it) => {
      if (it?.company === company.id) {
        return {
          company: company.id,
          isAffected: false,
          approved: false,
          isSiteVisitRequired: false,
        } as ComponentDataApplicationServiceProviderInput
      } else return it
    }) as ComponentDataApplicationServiceProviderInput[]
    company?.id && await resubmitApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          companyId: company?.id,
          applicationId: appData?.id || '',
          organizationId: user?.organization?.data?.id || '',
        },
      },
      onCompleted: () => {
        BlackNotification('Application resubmitted successfully.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
    })

    const newProgress = approvalsProgressCalculator(changedApprovalSet)

    appData?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: appData?.id,
        data: {
          approvalSet: changedApprovalSet,
          progress: newProgress,
        },
      },
      onCompleted: () => {
        BlackNotification('Application updated')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error application updating. ${error.message}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }

  return (
    <>
      <Title level={3}>
        {'Resubmitting application'}
      </Title>
      <Text size={'sm'}>
        {`You are going to re-submit the application `}
      </Text><Text size={'sm'} weight={'w700'}>{appData?.attributes?.title}</Text>
      <Text size={'sm'}>{' to the following company:'}</Text>


      {companiesDataLoading && (
        <Skeleton paragraph={{ rows: 5 }} loading={true} />
      )}
      <Row className={'tag-list-container'} style={{ paddingTop: 12 }}>
        <ItemTag
          mainText={company?.attributes?.name}
          secondaryText={company?.attributes?.companyEmail}
          iconType={'envelop'}
        />
      </Row>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
          loading={applicationUpdating || resubmitLoading || companiesDataLoading || appDataLoading}
        />
        <Button
          text={'Re-submit application'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={applicationUpdating || resubmitLoading || companiesDataLoading || appDataLoading}
          icon={<SvgIcon type={'envelop'} />}
          isIconWhite={true}
        />
      </Row>
    </>
  )
}
export default ResubmitApplication