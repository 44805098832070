import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItem, { SelectOptionsType } from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import MarkerCircle from '@/components/google-map/marker-circle/MarkerCircle'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import useSaveInviteHook from '@/components/modal/modal-content/hooks/useSaveKickoffInvite'
import Status, { StatusType } from '@/components/status/Status'
import { useInviteRequiredDocTypesQuery, useKickoffInviteQuery, useSendInviteMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useParticipantsOptions from '@/hooks/useParticipantsOptions'
import { Col, Form, Popconfirm, RadioChangeEvent, Row, Space, Spin } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { Feature, Point } from 'geojson'
import moment from 'moment'
import { FC, Ref, useEffect, useRef, useState } from 'react'
import { LngLatLike, MapRef, Marker, MarkerDragEvent } from 'react-map-gl'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import markerIcon from '../../google-map/images/marker-06.png'
import Typography from '../../typography/Typography'
import useApprovalSetUserProfiles from '@/hooks/useApprovalSetUserProfileIds'

const { Text } = Typography
const CreateKickoffInvite: FC = () => {
  const { user } = useAuth()
  const mapRef = useRef<MapRef>()
  const [form] = Form.useForm()
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [sendInvite, { loading: sendingInvite }] = useSendInviteMutation()

  const [location, setLocation] = useState<Point | null>(null)
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<string[]>([])
  const [isOtherDocumentsSelected, setIsOtherDocumentsSelected] = useState(false)

  const { data, loading: applicationLoading } = useApplicationByUuid()

  const { data: currentKickoffData, loading: currentKickoffLoading } = useKickoffInviteQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })

  const currentKickoff = currentKickoffData?.kickoffInvite?.data as KickoffInviteEntity
  const itemId = currentKickoff?.id

  const appData = data?.applications?.data?.[0] as ApplicationEntity

  const approvalSetUserProfilesId = useApprovalSetUserProfiles(appData);

  const applicationCenterFeature = appData?.attributes?.project?.data?.attributes?.projectCenter as Feature
  const applicationCenter = currentKickoff ? currentKickoff?.attributes?.location as Point : applicationCenterFeature?.geometry as Point

  const { data: docTypesData, loading: docTypesLoading } = useInviteRequiredDocTypesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const docTypes = docTypesData?.inviteRequiredDocumentTypes?.data
  const documentsOptions: SelectOptionsType[] = docTypes?.map(it => ({
    label: it?.attributes?.name || '',
    value: it?.id || '',
  })) || []

  const {approvalItemUserProfiles, options: participantsOptions, loading: userProfilesDataLoading} = useParticipantsOptions(approvalSetUserProfilesId)


  useEffect(() => {
    if (!applicationLoading && applicationCenter && !currentKickoffLoading && !currentKickoff) {
      setLocation(applicationCenter)
    }
    if (!currentKickoffLoading && currentKickoff) {
      setLocation(currentKickoff?.attributes?.location as Point)
      setIsOtherDocumentsSelected(!!currentKickoff?.attributes?.docDescription)
      setSelectedParticipantsIds(currentKickoff?.attributes?.participants?.data?.map(it => it?.id) as string[])
    }
  }, [applicationCenter, applicationLoading, currentKickoff, currentKickoffLoading])

  const { saveInvite, loading } = useSaveInviteHook({
    mapRef,
    appData,
    location,
  })

  const onMarkerDrag = (e: MarkerDragEvent) => {
    setLocation({
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    })
  }

  const onLoad = () => {

    mapRef?.current?.setCenter(applicationCenter?.coordinates as LngLatLike)
    mapRef?.current?.easeTo({ zoom: 14 })

    mapRef?.current?.loadImage(markerIcon, (error, image) => {
      if (error) throw error
      if (!mapRef?.current?.hasImage('custom-marker-icon') && image) {
        mapRef?.current?.addImage('custom-marker-icon', image)
      }
    })
  }
  const onSaveAndSubmit = async () => {
    const values = await form.validateFields()
    const resultId = await saveInvite(values, itemId, 'sent')
    if (resultId) {
      await sendInvite({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: { inviteId: resultId, organizationId: user?.organization?.data?.id || '' },
        },
        onCompleted: () => {
          BlackNotification('Invite sent successfully')
          setIsModalOpen(false)
        },
        onError: (error) => {
          BlackNotification(error.message)
        },
      })
    }
  }

  const handleSaveAsDraft = async (values: KickoffInviteInput) => {
    await saveInvite(values, itemId, 'draft')
    setIsModalOpen(false)
  }

  const onParticipantsChange = (idArray: string[]) => {
    setSelectedParticipantsIds(idArray)
  }
  const initialValues = {
    date: currentKickoff ? moment(currentKickoff?.attributes?.date, 'YYYY-MM-DD') : null,
    time: currentKickoff ? moment(currentKickoff?.attributes?.time, 'HH:mm SSS') : null,
    description: currentKickoff ? currentKickoff?.attributes?.description : null,
    participants: currentKickoff ? currentKickoff?.attributes?.participants?.data?.map(it => it?.id) : approvalSetUserProfilesId,
    inviteRequiredDocumentTypes: currentKickoff ? currentKickoff?.attributes?.inviteRequiredDocumentTypes?.data?.map(it => it?.id) : null,
    docDescription: currentKickoff ? currentKickoff?.attributes?.docDescription : null,
  }
  const locationPoint = {
    'type': 'Feature',
    'geometry': location,
  } as Feature<Point>

  const onDocumentsChange = (value: string | string[] | CheckboxValueType[] | RadioChangeEvent) => {
    const valuesArray = [...value as string[]]
    const otherDocId = docTypes?.find(it => it?.attributes?.slug === 'otherDocuments')?.id
    if (otherDocId) {
      setIsOtherDocumentsSelected(valuesArray?.includes(otherDocId))
    }
  }
  const recipientsEmails = approvalItemUserProfiles?.filter(it => it?.id && selectedParticipantsIds?.includes(it?.id))?.map(it => it?.attributes?.primaryEmail)?.toString()?.split(',')?.join(', ')
  return applicationLoading || currentKickoffLoading || docTypesLoading ? (
    <Spin />
  ) : (
    <>
      <FormItemDivider
        title={currentKickoff ? 'Edit Kickoff invite' : 'Create Kickoff invite'}
        subTitle={`${currentKickoff ? 'Edit' : 'Create'} you kickoff invite and send it to participants`}
        marginTop={0}
        marginBottom={10}
        extra={currentKickoff?.attributes?.status ? <Status status={currentKickoff?.attributes?.status as StatusType} accented={true} /> : null}
      />
      <Space direction={'vertical'} style={{ width: '100%', paddingTop: 8 }} size={'middle'} className={'big-modal-with-scroll-container'}>
        <Row>
          <Col span={24}>
            <MapBoxMap
              mapRef={mapRef as Ref<MapRef>}
              isDrawTools={false}
              isGeometryVisibilityToggle={false}
              mapContainerHeight={300}
              onLoad={onLoad}
            >
              {location && (
                <Marker
                  key={'center'}
                  longitude={location?.coordinates?.[0]}
                  latitude={location?.coordinates?.[1]}
                  draggable={true}
                  onDrag={onMarkerDrag}
                  style={{ opacity: 0 }} // to be able to drag marker but MarkerCircle will be rendered
                />
              )}
              <MarkerCircle locationPoint={locationPoint} />
            </MapBoxMap>
            <Text size={'sm'} color={'light-gray'}>{'Drag marker if needed to specify the exact place of meeting.'}</Text>
          </Col>
        </Row>
        <Form
          layout={'vertical'}
          size={'middle'}
          onFinish={handleSaveAsDraft}
          form={form}
          style={{ padding: '30px 0' }}
          initialValues={initialValues}
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem name={'date'} type={'date-picker'} label={'Date of meeting'} />
            </Col>
            <Col span={12}>
              <FormItem name={'time'} type={'time-picker'} label={'Time of meeting'} />
            </Col>
          </Row>
          <FormItem name={'description'} type={'textarea'} placeholder={'Enter description'} rows={5} label={'Description'} />
          <FormItem
            name={'participants'}
            type={'multi-select'}
            placeholder={'Select participants'}
            label={'Participants'}
            selectOptions={participantsOptions}
            handleMultiChange={onParticipantsChange}
            loading={userProfilesDataLoading}
            helpText={'Inside participants options you can find people selected as a "Site contacts" inside corresponding approval form.'}
          />
          <FormItem
            name={'inviteRequiredDocumentTypes'}
            type={'checkbox-group'}
            label={'Required Documents'}
            selectOptions={documentsOptions}
            required={false}
            handleChange={onDocumentsChange}
          />
          {isOtherDocumentsSelected ? (
            <FormItem
              name={'docDescription'}
              type={'textarea'}
              placeholder={'Enter description'}
              label={'Other document description'}
              required={false}
              helpText={'Please enter a "Other documents" description if you checked that option above.'}
            />
          ) : null}
          <Row justify={'end'} style={{ paddingTop: 24 }}>
            <Button text={itemId ? 'Update draft invite' : 'Save as draft'} btnType={'text'} htmlType={'submit'} loading={loading} />
            <Popconfirm
              placement={'topRight'}
              title={<Col style={{ maxWidth: '300px' }}>
                <Text>{`Invitation will be saved and sent to the following email addresses: `}</Text>
                <Text weight={'w700'}>{recipientsEmails}</Text>
              </Col>}
              onConfirm={onSaveAndSubmit}
              okText={'Send'}
              cancelText={'Cancel'}
              cancelButtonProps={{ loading: sendingInvite }}
              okButtonProps={{ loading: sendingInvite }}
            >
              <Button text={'Save and Send invite'} btnType={'primary'} loading={loading} />
            </Popconfirm>
          </Row>
        </Form>
      </Space>
    </>
  )
}
export default CreateKickoffInvite
