import EllipsisText from '@/components/ellipsis-text/EllipsisText'
import { IconTypes, SvgIcon } from '@/components/icon'
import { Col, Row, Tooltip } from 'antd'
import { FC } from 'react'
import './ItemTag.less'
import Typography from '../typography/Typography'
import Button from '../button/Button'

const { Paragraph } = Typography
type Props = {
  itemId?: Maybe<string>
  onClick?: (id: Maybe<string>)=>void
  mainText: Maybe<string>
  secondaryText?: Maybe<string>
  fontSize?: 'md' | 'sm' | 'xs'
  iconType?: IconTypes
  closable?: boolean
}
const ItemTag:FC<Props> = ({itemId, onClick,closable = false, iconType, mainText, secondaryText, fontSize = 'xs'}) => {
  return (
    <Row className={'item-tag'} align={'middle'}>
        <Col span={iconType ? 2 : 0}>
          <Row justify={'center'}>
            {iconType && <Button disabled={true} isTransparent={true} btnType={'icon'} icon={<SvgIcon type={iconType} />} />}
          </Row>
        </Col>
        <Col span={iconType ? 20 : 22}>
          <EllipsisText
            text={mainText}
            rows={1}
            ellipsisLength={30}
            style={{marginBottom: 0, maxWidth: 150 }}
            weight={'w700'}
            size={fontSize}
          />
          {secondaryText && (<Paragraph style={{marginBottom: 0}} color={'light-gray'}  size={'xs'}>{secondaryText}</Paragraph>)}
        </Col>
        <Col span={closable ? 2 : 0}>

          {closable &&
            <Tooltip title={'Delete item'} placement={'right'}>
              <Button size={'small'} btnType={'icon'} icon={<SvgIcon type={'close-notification'}/>} onClick={()=> onClick && onClick(itemId)} />
            </Tooltip>
            }
        </Col>
    </Row>
  )
}

export default ItemTag
