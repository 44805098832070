import Button from '@/components/button/Button'
import { getInitials } from '@/helpers/getInitials'
import classnames from 'classnames'
import { FC } from 'react'
import Typography from '../typography/Typography'
import './UserAvatar.less'

const { Text } = Typography
type Props = {
  avatarImageUrl?: string
  userFullName?: string
  isAdmin?: boolean
}
const UserAvatar:FC<Props> = ({isAdmin = false, avatarImageUrl, userFullName}) => {
  const classes = classnames({
    'avatar-button-wrapper': true,
    'is-admin': isAdmin
  })
  return (
    <div className={classes}>
      <Button type={'link'} className={'avatar-button'}>
        {avatarImageUrl ? <img src={avatarImageUrl} alt={'avatar'} /> : (
          <Text size={'xs'} weight={'w700'} className={'avatar-text'}>{getInitials(userFullName || '')}</Text>
        )}
      </Button>
    </div>
  )
}

export default UserAvatar
