import { Col, Row, Switch } from 'antd'
import { FC } from 'react'
import Typography from '../typography/Typography'

const { Text } = Typography

type Props = {
  onChange: (value: boolean) => void
  title?: string
}

const SwitchFormItem:FC<Props> = ({onChange, title}) => {
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col style={{marginRight: 8, paddingLeft: 5}}>
            <Switch defaultChecked={false} onChange={onChange} className={'gw-switcher'} />
          </Col>
          <Col>
            <Text size={'sm'} color={'gray'}>{title}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SwitchFormItem
