import './ClaimBanner.less'
import GetFreeQuoteButton from '@/components/website/get-free-quote-button/GetFreeQuoteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { FC } from 'react'
import bgImage from './images/action-plate-bg.png'
import classnames from "classnames";

const { Title } = WebsiteTypography


type BannerPosition = "absolute" | "default";

type ClaimBannerProps = {
  topOffset?: number;
  position?: BannerPosition;
};

const ClaimBanner: FC<ClaimBannerProps> = ({ topOffset = 150, position = "default" }) => {
  const bannerClass = classnames("banner-wrapper", {
    "banner-absolute": position === "absolute",
    "banner-default": position === "default",
  });

  return (
    <div className={bannerClass} style={position === "absolute" ? { top: topOffset, left: '50%', transform: 'translateX(-50%)' } : undefined}>
      <div className={"banner-bg-image-wrapper"}>
        <img src={bgImage} alt={""} />
      </div>
      <div className={"banner-text-block"}>
        <Title level={4} color={"white"} lh={"lh-sm"} weight={"w700"} className={"banner-title"}>
          {"Find out "}
          <span >{"how our solutions"}</span>
          <span style={{display: 'block'}}>{"can benefit you..."}</span>
        </Title>
      </div>
      <GetFreeQuoteButton type={"darkBlue"} />
    </div>
  );
};

export default ClaimBanner
