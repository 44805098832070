import Button from '@/components/button/Button'
import { useAuth } from '@/components/context/AuthProvider'
import { SvgIcon } from '@/components/icon'
import useOrganizationData from '@/hooks/useOrganizationData'
import { websiteRoutes } from '@/router/routes'
import { defaultPrimaryColor, EASETECH_COMPANY } from '@/utilities/global-variables'
import { Tooltip } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import classNames from 'classnames'
import { FC, useState } from 'react'
import './Sidebar.less'
import { useNavigate } from 'react-router'
import Logo from './logo/Logo'
import SiderMenu from './sider-menu/SiderMenu'
import Avatar from './avatar/Avatar'

const Sidebar: FC = () => {
  const {organization} = useAuth()
  const [collapsed, setCollapsed] = useState(false)
const {organizationData} = useOrganizationData()
  const navigate = useNavigate()
  const {website} = websiteRoutes

  const classes = classNames({
    'sider-content': true,
    'collapsed': collapsed,
  })

  const handleToggle = () => setCollapsed(!collapsed)
  const handleLogoClick = () => {
    navigate(`/${organization}${website.home}`)

  }
  return (
    <Sider
      collapsedWidth={100}
      width={180}
      className={'dashboard-sider'}
      collapsed={collapsed}
      theme={'light'}
      style={{backgroundColor: organization === EASETECH_COMPANY ? defaultPrimaryColor :  organizationData?.attributes?.primaryColor || defaultPrimaryColor}}
    >

      <div className={classes}>
      <Tooltip title={`${collapsed ? 'Expand' : 'Minimize'} sidebar`} placement={'right'}>
        <Button size={'small'} btnType={'icon'} icon={<SvgIcon type={collapsed ? 'arrow-right' : 'arrow-left'} />} onClick={handleToggle} className={'sider-collapse-button'} />
      </Tooltip>
        <div>
          <Logo onClick={handleLogoClick} collapsed={collapsed} />
          <SiderMenu collapsed={collapsed} />
        </div>
        <Avatar collapsed={collapsed} />
      </div>

    </Sider>
  )
}

export default Sidebar
