import BlackNotification from '@/components/blackNotification/BlackNotification'
import Button from '@/components/button/Button'
import { getUploadProps } from '@/components/google-map/helpers/uploadProps'
import { SvgIcon } from '@/components/icon'
import ImageFileCard from '@/components/image-file-card/ImageFileCard'
import { DefaultFileListItem } from '@/types/upload'
import { getBase64 } from '@/utilities/utilities'
import { message, Modal, Upload, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import { FC, SetStateAction, useState } from 'react'

type Props = {
  isUploadItemDisabled?: boolean
  isUploadDisabled?: boolean
  setUploadFilesIds: (ids: string[]) => void
  defaultFileList: DefaultFileListItem[]
  uploadButtonText?: string
}
const CustomUpload: FC<Props> = ({ isUploadDisabled = false, uploadButtonText, isUploadItemDisabled = false, setUploadFilesIds, defaultFileList }) => {
  const uploadProps = getUploadProps()

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState<SetStateAction<string> | undefined>('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = (await getBase64(file.originFileObj as File)) as string
    }
    setPreviewImage(file.url || file.preview)
    if (file.url) setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    setPreviewVisible(true)
  }

  const onUploadChange = (
    info: UploadChangeParam<UploadFile>,
  ) => {

    const uploadedIds = info?.fileList?.map(it => {
      if (it?.response) {
        return it?.response?.[0]?.id
      } else {
        return it?.uid
      }
    })

    setUploadFilesIds([...uploadedIds])
    const { status } = info.file

    if (status === 'done') {
      BlackNotification(`${info.file.name} file uploaded successfully.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`).then()
    }
  }
  const handleCancel = () => setPreviewVisible(false)

  return (
    <>
      <Upload
        {...uploadProps}
        onPreview={handlePreview}
        onChange={info => onUploadChange(info)}
        defaultFileList={defaultFileList}
        className={'kickoff-record-upload'}
        itemRender={(_, file, fileList, actions) => {
          const { remove } = actions
          return (
            <ImageFileCard
              name={file?.name}
              imageUrl={file?.response?.[0]?.url || file?.url}
              isDisabled={isUploadItemDisabled}
              isBordered={true}
              onRemoveItem={remove as () => Promise<void>}
              isShowRemoveButton={true}
              isShowMenuButton={true}
              marginBottom={8}
            />
          )
        }}
      >
        <Button block btnType={'ghost'} text={uploadButtonText ?? 'Upload file'} icon={<SvgIcon type={'download'} />} disabled={isUploadDisabled} />
      </Upload>
      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} closeIcon={<SvgIcon type={'close-modal'} />}>
        <img alt={'example'} style={{ width: '100%' }} src={previewImage as string} />
      </Modal>
    </>
  )
}

export default CustomUpload
