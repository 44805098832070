import { SvgIcon } from '@/components/icon'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import React from 'react'

const projectMenu: ItemType[] = [
  // {
  //   key: 'projects',
  //   icon: <SvgIcon type={'project-info'} />,
  //   label: 'Projects',
  //   title: '',
  // },

  {
    key: 'overview',
    icon: <SvgIcon type={'sidebar-overview'} />,
    label: 'Overview',
    title: '',
  },
  {
    key: 'applications',
    icon: <SvgIcon type={'sidebar-applications'} />,
    label: 'Applications',
    title: '',
  },
]


export const genProjectMenuItems = () => {
  return projectMenu
}