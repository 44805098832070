import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { Loader } from '@/components/layout/Loader'
import { message } from 'antd'
import { useState } from 'react'
import { useApplicationQuery, useDeleteApplicationMutation, useDeleteProjectMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const DeleteApplication = () => {
  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationQuery({
    variables: { id: currentId },
    fetchPolicy: globalFetchPolicy,
  })
  const currentApplication = data?.application?.data
  const [deleteApplication, { loading }] = useDeleteApplicationMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onDelete = async () => {
    await deleteApplication({
      variables: {
        id: currentId,
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Application deleted')
        setIsModalOpen(false)
        setTriggerRefetch(true)
      },
    })
  }
  if (appDataLoading) return <Loader />
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Deleting Application'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`You are going to delete current application with number: `}
        <Text size={'lg'} weight={'w700'}>{currentApplication?.attributes?.title}</Text>
      </Paragraph>
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading || appDataLoading} />
      </div>
    </>
  )
}
export default DeleteApplication
