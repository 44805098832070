import "./ModalContent.less"

import { message, Spin } from "antd"
import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useApplicationsQuery, useReportPdfMutation, useUpdateApplicationMutation } from "../../../graphql"
import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import { SvgIcon } from "../../icon"
import Typography from "../../typography/Typography"

const { Text, Paragraph } = Typography

type AppReportData = {
  agent: {
    title: string
    value: string
  }
  appNumber: string
  company: string
  department: string
  info: {
    title: string
    value: string
  }[]

  location: {
    title: string
    value: string
  }[]
  status: string
}
type AppReportDataType = {
  affectedSuburbs: string
  agent: string
  agentEmail: string
  appNumber: string
  appType: string
  client: string
  department: string
  endDate: string
  erf: string
  locationDescription: string
  municipality: string
  nature: string
  region: string
  roadDescription: string
  roadType: string
  startDate: string
  status: string
  street01: string
  street02: string
}
const applicationReportData: AppReportDataType = {
  appNumber: "BFS21-0030",
  status: "Created",
  client: "AT&T Corp. Infrastructure",
  startDate: "Jan 24, 2022",
  endDate: "Jan 27, 2022",
  department: "Logistic department",
  nature: "Gas infrastructure, Fibre infrastructure, Electrical infrastructure...",
  agent: "Kearns, Helena",
  agentEmail: "kearns.helena@gmail.com",
  erf: "154-148-DC21",
  street01: "Niepodleglosti St",
  street02: "Quezone Avenue",
  affectedSuburbs: "Kraaifontein, Kuils River",
  appType: "Hoarding Permit",
  municipality: "Roodepoort",
  region: "South Africa",
  locationDescription: "National Road Affected",
  roadType: "National Road Affected",
  roadDescription: "National Road Affected",
}

const reportData: AppReportData = {
  appNumber: "BFS21-0030",
  status: "Created",
  company: "AT&T Corp. Infrastructure",
  department: "Logistic department",
  info: [
    {
      title: "Client",
      value: "AT&T Corp. Infrastructure",
    },
    {
      title: "Estimated Start Date",
      value: "Jan 24, 2022",
    },
    {
      title: "Estimated End Date",
      value: "Jan 15, 2023",
    },
    {
      title: "Nature of the Application",
      value: "Gas infrastructure, Fibre infrastructure, Electrical infrastructure... ",
    },
    {
      title: "Department",
      value: "Logistic department ",
    },
  ],
  agent: {
    title: "Kearns, Helena",
    value: "kearns.helena@gmail.com",
  },
  location: [
    {
      title: "Erf Numbers",
      value: "154-148-DC21",
    },
    {
      title: "Street 1",
      value: "Niepodleglosti St",
    },
    {
      title: "Street 2",
      value: "Quezone Avenue",
    },
    {
      title: "Affected Suburbs",
      value: "Kraaifontein, Kuils River",
    },
    {
      title: "Application Type",
      value: "Hoarding Permit",
    },
    {
      title: "Municipality",
      value: "Roodepoort",
    },
    {
      title: "Region",
      value: "South Africa",
    },
    {
      title: "Description",
      value: "National Road Affected",
    },
    {
      title: "Type Road",
      value: "National Road Affected",
    },
    {
      title: "Description",
      value: "Main roads or primary streets",
    },
  ],
}

const ApplicationReport = () => {
  const { setIsModalOpen, setModalType } = useGeneralContext()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id") as string
  const { data, loading } = useApplicationsQuery({ variables: { filter: { uuid: { eq: id } } } })
  const [updateApplication] = useUpdateApplicationMutation()
  // ===> possibly need to pass in to pdfReportRenderer
  // const mapImage = data?.applications?.data?.[0]?.attributes?.mapImage ? data?.applications?.data?.[0]?.attributes?.mapImage : 'no map image'
  const title = data?.applications?.data?.[0]?.attributes?.title
    ? "Application-" + data?.applications?.data?.[0]?.attributes?.title
    : "application..."
  const link = data?.applications?.data?.[0]?.attributes?.applicationLetter
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = () => {
    updateApplication({
      variables: {
        id: id,
        data: {
          confirmed: true,
        },
      },
    })
      .then(() => {
        setModalType("create-application-success")
      })
      .catch(e => {
        message.error("Server error, try again.", e?.message).then()
      })
  }
  return !loading ? (
    <>
      <Paragraph size={"xl"} style={{ marginBottom: 24 }}>
        {"Report"}
      </Paragraph>
      <Paragraph style={{ marginTop: 24 }} size={"sm"}>
        {"Review the report based on the information provided above, which services will work in your project."}
      </Paragraph>
      <div className='report-download'>
        <div className='label-block'>
          <div className='doc-icon-wrapper'>
            <SvgIcon type={"doc"} />
          </div>
          <div className='name-box'>
            <Paragraph size={"md"} weight={"w700"}>
              {`${title}.pdf`}
            </Paragraph>
            <Text color={"gray"}>{"1.4 MB"}</Text>
          </div>
        </div>
        <a href={`http://${link}`} target='_blank' rel='noreferrer' className='download-icon-wrapper'>
          <SvgIcon type={"download"} />
        </a>
      </div>
      <div className='btn-block'>
        <Button text={"Cancel"} btnType={"text"} onClick={onCancel} />
        <Button text={"Confirm"} btnType={"primary"} onClick={onConfirm} />
      </div>
    </>
  ) : (
    <Spin />
  )
}
export default ApplicationReport
