import industriesImg from '@/pages/website-landing/components/short-section/images/ind-solutions-img.png'
import ShortSection, { ShortSectionContent } from '@/pages/website-landing/components/short-section/ShortSection'
import InfoContact from '@/pages/website-landing/home/sections/info-contact/InfoContact'
import { FC } from 'react'

const blogTemporaryInfo: ShortSectionContent = {
  titleRegular: 'Coming ',
  titleBold: 'Soon',
  paragraphFirst: 'In the meantime, feel free to explore our other pages for more information or contact us for personalized assistance.',
  imgContent: industriesImg
}

const Blog: FC = () => {
  return (
    <>
      <ShortSection content={blogTemporaryInfo}/>
      <InfoContact isBannerVisible={false} paddingTop={'sm'}/>
    </>
  )
}

export default Blog

