import BlackNotification from '@/components/blackNotification/BlackNotification'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCreateQuoteItemMutation } from '@/graphql'
import { Form } from 'antd'
import { FC } from 'react'

import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title } = Typography

const CreateQuotationItem:FC = () => {
  const { setIsModalOpen } = useGeneralContext()
  const [createQuoteItem] = useCreateQuoteItemMutation()

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onFinish = async (values: QuoteItemInput) => {
    await createQuoteItem({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: values
      },
      onError: (error) => {
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
      onCompleted: () => {
        BlackNotification('Quote item created successfully')
        setIsModalOpen(false)
      },
      refetchQueries: 'active'
    })
  }

  return (
    <Form<QuoteItemInput> layout={'vertical'} className={'create-quotation-item'} onFinish={onFinish}>
      <Title level={3}>{'Create quotation item'}</Title>
      <FormItem name={'name'} type={'input'} label={'Item name'} placeholder={'Enter the quotation item name'}/>
      <FormItem name={'description'} type={'input'} label={'Item description'} placeholder={'Enter the quotation description'} />
      <FormItem name={'price'} type={'input-number'} label={'Item price (R)'} placeholder={'Enter the quotation price (R)'} />
      <div className={'btn-block'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Create'} btnType={'primary'} htmlType={'submit'} />
      </div>
    </Form>
  )
}
export default CreateQuotationItem
