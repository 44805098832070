import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import ClaimBanner from '@/pages/website-landing/components/claim-banner/ClaimBanner'
import ContactForm from '@/pages/website-landing/home/sections/info-contact/contact-form/ContactForm'
import { Col, Row } from 'antd'
import { FC } from 'react'
import infoBg from './images/useful-info-bg.webp'

const { Title } = WebsiteTypography
import './InfoContact.less'

type Props = {
  isBannerVisible?: boolean
  paddingTop: 'sm' | 'lg'
}

const InfoContact: FC<Props> = ({isBannerVisible=true, paddingTop}) => {

  let paddingTopSize

  switch (paddingTop) {

    case 'sm': paddingTopSize = 80
      break
    case 'lg': paddingTopSize = 195
      break
    default: paddingTopSize = 195

  }

  return (
    <div className={'info-section-wrapper'}>
      {isBannerVisible && (<ClaimBanner position={'absolute'} topOffset={-105} />)}
      <div className={'info-section-bg'}>
        <img src={infoBg} alt={''} />
      </div>
      <div className={'container'}>
        <Row justify={'center'} className={'form-container'} id={'contact'} style={{ paddingTop: paddingTopSize }}>

          <Col span={16}>
            <Title level={3} centered className={'form-section-title'}>
              <span className={'bold'}>{'Quick '}</span>
              <span className={'w300'}>{'Contact '}</span>
            </Title>
            <ContactForm />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InfoContact
