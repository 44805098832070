import { Modal as Wrapped, ModalProps } from "antd"
import { FC } from "react"

import { useGeneralContext } from "../context/GeneralContext"
import { SvgIcon } from "../icon"

type ModalCustomProps = {
  topOffset?: number | null
}
const Modal: FC<ModalCustomProps & ModalProps> = ({topOffset = 100, ...baseProps }) => {
  const { setIsModalOpen, setModalWidth } = useGeneralContext()
  const onCancel = () => {
    setIsModalOpen(false)
    setModalWidth(null)
  }
  return (
    <Wrapped
      footer={false}
      className={"main-modal"}
      closeIcon={<SvgIcon type={"close-modal"} />}
      onCancel={onCancel}
      destroyOnClose={true}
      style={{top: topOffset || 100}}
      {...baseProps}
    />
  )
}

export { Modal }
