export const createdAppDataLocalStore = 'create-application-data'
export const createdProjectId = 'created-project-id'
export const createdProjectCategory = 'created-project-category'
export const createAppMapSnapshot = 'create-application-map-snapshot'
export const DateFormat = 'DD MMM YYYY'
export const longDash = '—'

export const APPLICATION_PROGRESS = {
  created: 5,
  documents_uploaded: 15,
  paid: 15,
  ready_for_submitting: 20,
  on_review: 25,
  reviewed: 30,
  submitted: 35
}