import './Header.less'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import GetFreeQuoteButton from '@/components/website/get-free-quote-button/GetFreeQuoteButton'
import { useOrganizationsQuery } from '@/graphql'
import { getAuthToken } from '@/helpers/auth'
import { websiteRoutes } from '@/router/routes'
import { defaultPrimaryColor, EASETECH_COMPANY } from '@/utilities/global-variables'

import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { Menu, MenuProps, Skeleton } from 'antd'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import websiteTypography from '@/components/websiteTypography/WebsiteTypography'

const { Text } = websiteTypography

const items = [
  {
    label: 'home',
    key: 'home',
  },
  {
    label: 'our services',
    key: 'our-services',
  },
  {
    label: 'industries',
    key: 'industries',
  },
  {
    label: 'blog',
    key: 'blog',
  },
]

const Header:FC = () => {

  const navigate = useNavigate()
  const { website, dashboard } = websiteRoutes
  const params = useParams()
  const currentOrgSlug = params.org || EASETECH_COMPANY
  const { pathname }  = useLocation()

  const parts = pathname.split('/');
  const currentPageKey = parts[parts.length - 1];

  const [pageKey, setPageKey] = useState(currentPageKey)

   const {data, loading} = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {slug: {eq: currentOrgSlug}}
    }
  })

 useEffect(()=>{

   setPageKey(currentPageKey)
 }, [currentPageKey])

  const currentOrganization = data?.organizations?.data?.[0]

  const onLogoClick = () => {

    setPageKey('home')
    navigate(`/${currentOrgSlug}${website.home}`)
  }

  const onClick: MenuProps['onClick'] = async (e) => {

    if (e.key === 'home') {
      navigate(`/${currentOrgSlug}${website.home}`)
    }
    if (e.key === 'our-services') {
      navigate(`/${currentOrgSlug}${website.ourServices}`)
    }
    if (e.key === 'industries') {
      navigate(`/${currentOrgSlug}${website.industries}`)
    }
    if (e.key === 'blog') {
      navigate(`/${currentOrgSlug}${website.blog}`)
    }
    // if (e.key === 'contact') {
    //   navigate(`/${currentOrgSlug}${website.home}`)
    //   scrollToSection('contact')
    // }
  }
  const onGoToDashboard = () => {
    navigate(dashboard.overview)
  }


  return (
    <div className={'main-header'} style={{backgroundColor: currentOrganization?.attributes?.primaryColor || defaultPrimaryColor}}>
        <div className={'header-content'}>
          <div className={'header-logo-wrapper'} onClick={onLogoClick}>
            {loading ? <Skeleton loading={true} paragraph={{rows: 1}} /> : (
             <img src={currentOrganization?.attributes?.logoWithCompanyName?.data?.attributes?.url} alt={'logo'} />
            ) }
          </div>
          <div className={"header-content-wrapper"}>
            {currentOrgSlug === EASETECH_COMPANY && (
              <Menu selectedKeys={[pageKey]} items={items} mode={'horizontal'} className={'header-menu'} onClick={onClick} />
            )}
            {!getAuthToken() ? (
              <div className={'btn-block'}>

                <Link to={`/${currentOrgSlug}${website.auth}/login`}>
                  <WebsiteButton btnType={'ghost-white'} style={{ marginRight: 8 }}>
                    <Text size={'md'}>{'SIGN IN'}</Text>
                  </WebsiteButton>
                </Link>

                <Link to={`/${currentOrgSlug}${website.auth}/client`}>
                  <WebsiteButton btnType={'ghost-white'} style={{ marginRight: 8 }}>
                    <Text size={'md'}>{'REGISTER'}</Text>
                  </WebsiteButton>
                </Link>

                <GetFreeQuoteButton type={"orange"}/>

              </div>
            ) : (
              <WebsiteButton onClick={onGoToDashboard}>{'go to dashboard'}</WebsiteButton>
            )}
          </div>

        </div>
      </div>
  )
}

export default Header
