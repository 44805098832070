import { TOOLTIP_MESSAGES } from '@/components/app'
import { getAuthToken } from '@/helpers/auth'
import { websiteRoutes } from '@/router/routes'
import { UploadedFileAWSResponse } from '@/types/upload'
import { Col, Row, Tooltip, Upload, UploadProps } from 'antd'
import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import Button from '../button/Button'
import { SvgIcon } from '../icon'
import './AddGenerateDocumentBtnBlock.less'

type Props = {
  onUpload: (info: any) => void
  fileList?: UploadedFileAWSResponse[]
}
const AddGenerateDocumentBtnBlock: FC<Props> = ({ onUpload }) => {
  const { dashboard } = websiteRoutes
  const [_, setApprovalDetails] = useLocalStorage('approval-details')
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id') as string
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const props: UploadProps = {
    name: 'files',
    action: `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
    listType: 'picture',
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    multiple: true,
    showUploadList: false,
    style: { width: '100%', border: '1px solid red'},
    className: 'approval-form-upload'
  }
  const onGenerateClick = () => {
    setApprovalDetails({
      pathname,
      uuid,
    })
    navigate(dashboard.templateSubjects)
  }
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Upload {...props} onChange={info => onUpload(info)} >
          <Button
            size={'small'}
            text={'Add document'}
            icon={<SvgIcon type={'plus'} />}
            btnType={'ghost'}
            style={{ width: '100%' }}
            block={true}
          />
        </Upload>
      </Col>
      <Col span={12}>
        <Tooltip title={TOOLTIP_MESSAGES.temporaryUnavailable}>
          <div>
            <Button
              text={'Generate document'}
              icon={<SvgIcon type={'doc-sheet'} />}
              btnType={'ghost'}
              size={'small'}
              onClick={onGenerateClick}
              style={{ width: '100%' }}
              disabled
            />
          </div>
        </Tooltip>
      </Col>
    </Row>
  )
}

export default AddGenerateDocumentBtnBlock
