import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useDeleteTaskMutation, useTaskQuery } from '@/graphql'
import { websiteRoutes } from '@/router/routes'
import { message, Row, Skeleton } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const DeleteTask: FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()
  const { dashboard } = websiteRoutes
  const navigate = useNavigate()

  const [deleteTask, { loading }] = useDeleteTaskMutation()

  const {data, loading: taskLoading} = useTaskQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {id: currentId},
    skip: !currentId
  })
  const currentTask = data?.task?.data as TaskEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onDelete = async () => {
    if (currentId) {
      await deleteTask({
        fetchPolicy: globalFetchPolicy,
        variables: {
          id: currentId,
        },
        onError: (error) => {
          message.error(error.message)
          setIsModalOpen(false)
        },
        onCompleted: () => {
          BlackNotification('Task deleted successfully')
          navigate(dashboard.tasks)
          setIsModalOpen(false)
        },
        refetchQueries: 'active',
      })
    }
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Deleting task'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`Please, confirm deleting selected task`}
      </Paragraph>
      {taskLoading ? <Skeleton /> : (
        <>
          <Paragraph style={{ marginTop: 32 }} size={'sm'} >
            {`Title: `}
            <Text size={'sm'} weight={'w700'}>{currentTask?.attributes?.title}</Text>
          </Paragraph>
          <Paragraph size={'sm'} weight={'w400'}>
            {`Description: `}
            <Text size={'sm'} weight={'w700'}>{currentTask?.attributes?.description}</Text>
          </Paragraph>
        </>
      )}
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading} />
      </Row>
    </>
  )
}
export default DeleteTask
