import { useAuth } from '@/components/context/AuthProvider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { message } from 'antd'
import { useSearchParams } from 'react-router-dom'
import {
  useApplicationsQuery,
  useCreateNotificationMessageMutation,
  useQuoteIssuedEmailNotificationMutation,
  useUpdateApplicationMutation,
  useUpdateQuotationMutation,
} from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const SendQuotation = () => {
  const { user } = useAuth()

  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()
  const [searchParams] = useSearchParams()
  const currentAppUuid = searchParams?.get('id') as string
  const [updateQuotation] = useUpdateQuotationMutation()
  const [updateApplication] = useUpdateApplicationMutation()
  const [createNotification] = useCreateNotificationMessageMutation()
  const [quoteIssuedEmailSending] = useQuoteIssuedEmailNotificationMutation()

  const { data, loading } = useApplicationsQuery({
    variables: { filter: { uuid: { eq: currentAppUuid } } },
    fetchPolicy: globalFetchPolicy,
  })
  const applicationData = data?.applications?.data?.[0]
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    await updateQuotation({
      variables: {
        id: currentId,
        data: {
          status: 'sent',
        },
      },
      fetchPolicy: globalFetchPolicy,
      onError: (error) => {
        message.error(error.message)
      },
    })
    applicationData?.id && await updateApplication({
      variables: {
        id: applicationData?.id,
        data: {
          progress: 10,
          status: 'quote_issued',
        },
      },
      fetchPolicy: globalFetchPolicy,
      onCompleted: () => {
        setIsModalOpen(false)
        setTriggerRefetch(true)
        applicationData?.id && quoteIssuedEmailSending({
          variables: {
            input: {
              applicationId: applicationData?.id,
            },
          },
          fetchPolicy: globalFetchPolicy,
        })
        BlackNotification(
          'Quotation sent successfully. Click menu button to check the generated pdf quotation document',
        )
      },
      onError: (error) => {
        message.error(error.message)
      },

    })
    await createNotification({
      variables: {
        input: {
          application: applicationData?.id,
          from: user?.userProfile?.data?.id,
          to: applicationData?.attributes?.project?.data?.attributes?.applicant?.data?.id,
          message: `Quotation for application ${applicationData?.attributes?.title} has been issued, please review.`,
        },
      },
      fetchPolicy: globalFetchPolicy,
      onError: (error) => {
        message.error(error.message)
      },
    })


  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Sending Quotation'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w700'}>
        {'You are going to send quotation to client'}
      </Paragraph>
      <Paragraph style={{ marginTop: 20 }} size={'sm'}>
        {'After receiving the quotation client can request an invoice and pay for that application'}
      </Paragraph>
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Send'} btnType={'primary'} onClick={onConfirm} disabled={loading} />
      </div>
    </>
  )
}
export default SendQuotation
