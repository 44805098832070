import './ImageFileCard.less'
import DoubleDotsMenu from '@/components/double-dots-menu/DoubleDotsMenu'
import EllipsisText from '@/components/ellipsis-text/EllipsisText'
import { getImageSize } from '@/helpers/getImageSize'
import { sleep } from '@/helpers/sleeper'
import { DocExtensions, GeometryExtensions, ImageExtensions } from '@/types/upload'
import { getFileFromUrl } from '@/utilities/getFileFromUrl'
import { getUrlExtension } from '@/utilities/utilities'

import { Modal, Skeleton, Tooltip } from 'antd'
import classnames from 'classnames'
import downloadjs from 'downloadjs'
import React, { FC, MouseEventHandler, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Button from '../button/Button'

import { SvgIcon } from '../icon'
import Typography from '../typography/Typography'
import { getFileSizeFromUrl } from '@/helpers/getFileSizeFromUrl'

const { Text, Paragraph } = Typography
type CardProps = {
  itemId?: InputMaybe<IdFilterInput>
  imageUrl?: string | null | undefined
  name: string
  size?: Maybe<number | string>
  isAdd?: boolean
  onDelete?: MouseEventHandler<HTMLElement>
  isBordered?: boolean
  ext?: ImageExtensions | DocExtensions | GeometryExtensions | string
  isShowRemoveButton?: boolean
  isShowMenuButton?: boolean
  onRemoveItem?: (id: InputMaybe<IdFilterInput>) => Promise<void>
  handleRemoveDocFromSlot?: (slotTitle: Maybe<string>, id: InputMaybe<IdFilterInput>) => Promise<void>
  slotTitle?: Maybe<string>
  isDisabled?: boolean
  marginBottom?: number
}

const ImageFileCard: FC<CardProps> = ({
                                        ext,
                                        itemId,
                                        isShowRemoveButton = false,
                                        isShowMenuButton = false,
                                        onRemoveItem,
                                        isBordered = false, imageUrl, name, size, isAdd = false, onDelete,
  handleRemoveDocFromSlot,
  slotTitle,
  isDisabled = false,
  marginBottom
                                      }) => {
  const extension = (ext || imageUrl && getUrlExtension(imageUrl))
  const [visible, setVisible] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [fileSize, setFileSize] = useState<number | null>(null)
  const onDownload = async (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (imageUrl) {
      // imageUrl && downloadFile(imageUrl) //===> that is opening in a blank tab
      const fileData = await getFileFromUrl(imageUrl)
      downloadjs(fileData, `${name}${extension}`)
    }
  }
  useEffect(() => {
    const fetchFileSize = async () => {
      if (!size && imageUrl) {
        const fileSizeInMB = await getFileSizeFromUrl(imageUrl);
        setFileSize(fileSizeInMB);
      }
    };

    fetchFileSize().then();
  }, [imageUrl, size])
  
  const onImagePreview = async () => {
    setIsLoading(true)
    if (extension !== '.json' && extension !== '.kml' && extension !== '.doc') {
      setVisible(true)
      await sleep(500)
      setIsLoading(false)
    }
  }
  const handleCancel = () => setVisible(false)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  async function onDocumentLoadSuccess(props: { numPages: number }) {
    const { numPages } = props
    setPageNumber(numPages)
  }

  const cardStyles = classnames({
    'image-card': true,
    'bordered': isBordered,
  })
  const menuItems = [{ label: 'Download file', link: '', onClick: onDownload }]
  if (isShowRemoveButton && onRemoveItem) {
    menuItems.push({ label: 'Remove file', link: '', onClick: () => onRemoveItem(itemId) })
  }
  return (
    <div className={cardStyles} style={{marginBottom: marginBottom ?? 0}}>
      <div className={'image-block'}>
        {imageUrl && (extension !== '.pdf' && extension !== '.json' && extension !== '.kml' && extension !== '.doc') ? (
          <Tooltip title={'Preview image'}>
            <Button btnType={'text'} className={'file-image-wrapper'} onClick={onImagePreview}>
              <img src={imageUrl} alt={''} />
            </Button>
          </Tooltip>
        ) : (
          <Button btnType={'text'} className={'file-image-wrapper'} onClick={onImagePreview} disabled={!imageUrl}>
            <SvgIcon type={'doc'} />
          </Button>
        )}
        <div className={'image-text-block'}>
          <EllipsisText text={name} rows={1} ellipsisLength={30} maxWidth={300}  />
          {fileSize && <Text size={'xs'} color={'gray'}>{`${fileSize} MB`}</Text>}
        </div>
      </div>
      <div className={'icon-block'}>
        {/*<a href={imageUrl} download={'file-name.png'} target={'_blank'}>*/}
        <Button btnType={'icon'} icon={<SvgIcon type={'download'} onClick={onDownload} />} disabled={!imageUrl} />
        {/*</a>*/}
        {isAdd && <Button btnType={'icon'} icon={<SvgIcon type={'clip'} />} />}
        {isShowRemoveButton && onRemoveItem && <Button btnType={'icon'} icon={<SvgIcon type={'bin'} />} onClick={() => onRemoveItem(itemId)} disabled={isDisabled} />}
        {isShowRemoveButton && handleRemoveDocFromSlot && <Button btnType={'icon'} icon={<SvgIcon type={'bin'} />} onClick={() => handleRemoveDocFromSlot(slotTitle, itemId)} disabled={isDisabled} />}
      </div>
      {isShowMenuButton && <DoubleDotsMenu menuItems={menuItems} disabled={isDisabled} />}

      <Modal
        width={800}
        open={visible}
        onCancel={handleCancel}
        title={''}
        footer={null}
        closeIcon={<SvgIcon type={'close-modal'} />}
        className={'preview-image-modal'}
      >
        {(extension === '.jpg' || extension === '.png' || extension === '.jpeg' || extension === '.webp') && (
          <div className={'preview-image-wrapper'}>
            <img src={imageUrl || ''} alt={'image'} style={{ width: '100%' }} />
          </div>
        )}
        {extension === '.pdf' && (
          <Document
            file={{ url: imageUrl || '' }}
            options={{ enableXfa: false, cMapPacked: false, disableStream: true, isEvalSupported: false }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={isLoading}
          >

            {isLoading ? <Skeleton loading paragraph={{ rows: 20 }} /> : Array.from({ length: pageNumber }, (_, index) => (
              <Page
                key={index + 1}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
        )}
      </Modal>
    </div>
  )
}
export default ImageFileCard
