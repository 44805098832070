import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import { getMapSnapshot } from '@/components/google-map/helpers/getMapSnapshot'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { StatusType } from '@/components/status/Status'
import { useCompaniesLazyQuery, useCreateKickoffInviteMutation, useUpdateInviteMutation } from '@/graphql'
import { Point } from 'geojson'
import moment from 'moment'
import { MutableRefObject, useCallback, useState } from 'react'
import { MapRef } from 'react-map-gl'

interface UseSaveAsDraftProps {
  mapRef: MutableRefObject<MapRef | undefined>
  appData: ApplicationEntity;
  location: Point | null;
}

const useSaveInviteHook = ({ mapRef, appData, location }: UseSaveAsDraftProps) => {
  const {user} = useAuth()
  const [loading, setLoading] = useState(false);
  const [createInvite] = useCreateKickoffInviteMutation()
  const [updateInvite] = useUpdateInviteMutation()

  const saveInvite = useCallback(
    async (values: KickoffInviteInput, itemId: Maybe<string>, status: InputMaybe<EnumKickoffinviteStatus>) => {
      try {
        setLoading(true);
        const participants = values?.participants as string[];
        const mapSnapshot = await getMapSnapshot(mapRef) as unknown as (UploadFileInput & { id: string })[];

        // ==== If open existing invite and change it =============
        if(itemId){
          const result = await updateInvite({
            fetchPolicy: globalFetchPolicy,
            variables: {
              id: itemId,
              data: {
                date: moment(values?.date).format('YYYY-MM-DD'),
                time: moment(values?.time).format('HH:mm:ss.SSS'),
                description: values?.description,
                inviteRequiredDocumentTypes: values?.inviteRequiredDocumentTypes,
                status,
                application: appData?.id,
                location,
                participants,
                mapSnapshot: mapSnapshot?.[0]?.id,
              },
            },
            refetchQueries: 'active',
            onCompleted: () => {
              BlackNotification('Invite updated successfully');
            },
            onError: error => {
              BlackNotification(`Invite update failed. ${error.message}`);
            },
          })
          return result?.data?.updateKickoffInvite?.data?.id
        } else {
          // ============ CREATE NEW INVITE ================
          const result = await createInvite({
            fetchPolicy: globalFetchPolicy,
            variables: {
              input: {
                date: moment(values?.date).format('YYYY-MM-DD'),
                time: moment(values?.time).format('HH:mm:ss.SSS'),
                description: values?.description,
                inviteRequiredDocumentTypes: values?.inviteRequiredDocumentTypes,
                status,
                application: appData?.id,
                location,
                mapSnapshot: mapSnapshot?.[0]?.id,
                participants,
                owner: user?.userProfile?.data?.id,
                docDescription: values?.docDescription
              },
            },
            refetchQueries: 'active',
            onCompleted: () => {
              BlackNotification('Invite created successfully');
            },
            onError: error => {
              BlackNotification(`Invite creation failed. ${error.message}`);
            },
          })
          return result?.data?.createKickoffInvite?.data?.id
        }
      } catch (error) {
        console.error('Error saving as draft:', error);
        return null
      } finally {
        setLoading(false);
      }
    },
    [appData?.id, createInvite, location, mapRef, updateInvite, user?.userProfile?.data?.id]
  );

  return { saveInvite, loading };
};

export default useSaveInviteHook;
