import { useOrganizationsQuery } from '@/graphql'
import { useMemo } from 'react'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { SelectOptionsType } from '@/components/form-item/FormItem'

interface UseOrganizationsOptionsHook {
  orgOptions: SelectOptionsType[];
  loading: boolean;
}

export type ModeType = |
  'all'


const useOrganizationsOptions = (mode: ModeType = 'all'): UseOrganizationsOptionsHook => {
  let filters: OrganizationFiltersInput | null
  switch (mode) {
    case 'all':
      filters = {}
      break
    default:
      filters = {}
  }
  const { data, loading } = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: filters,
      pagination: { limit: -1 },
    },
  })

  const orgOptions = useMemo(() => {
    const org = data?.organizations?.data as OrganizationEntity[]
    return org?.map((it) => ({
      label: it?.attributes?.name as string,
      value: it?.id as string,
    })) || [{ label: '', value: '' }]
  }, [data?.organizations?.data])

  return {
    orgOptions,
    loading,
  }
}

export default useOrganizationsOptions
