import FormItemDivider from '@/components/form-item/FormItemDivider'
import ImageFileCard from '@/components/image-file-card/ImageFileCard'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import UserContactCard from '@/components/user-contact-card/UserContactCard'
import { useCompanyQuery } from '@/graphql'
import { removeUnderscores } from '@/helpers/removeUnderscores'
import { Row, Space } from 'antd'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const CompanyInfo: FC = () => {
  const { setIsModalOpen, currentId, setIsDrawerOpen } = useGeneralContext()

  const { data, loading } = useCompanyQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const company = data?.company?.data as CompanyEntity
  const employees = company?.attributes?.userProfiles?.data as UserProfileEntity[]
  console.log(company)

  const onCancel = () => {
    setIsModalOpen(false)
    setIsDrawerOpen(false)
  }

  return (
    <>
      <Title level={3} style={{ marginBottom: 0 }}>
        {company?.attributes?.name}
      </Title>
      <Paragraph color={'light-gray'} style={{}}>{removeUnderscores(company?.attributes?.companyType || '')}</Paragraph>

      <FormItemDivider label={'Contact info'} />
      <InfoTextItem label={'Address'} text={company?.attributes?.address} />
      <InfoTextItem label={'Email'} text={company?.attributes?.companyEmail} />
      <InfoTextItem label={'Phone'} text={company?.attributes?.phone} />
      {company?.attributes?.applicationMethods?.data?.length ? (
        <>
          <FormItemDivider label={'Application method'} marginTop={30} />
          {company?.attributes?.applicationMethods?.data?.map((it, index) => (
            <InfoTextItem key={it?.id} label={`Method ${index + 1}`} text={it?.attributes?.title} />
          ))}
        </>
      ) : null}
      {company?.attributes?.appMethodDescription && (
        <>
          <FormItemDivider label={'Application methods description'} marginTop={30} />
          <ReactMarkdown>{company?.attributes?.appMethodDescription}</ReactMarkdown>
        </>
      )}
      {company?.attributes?.documentTemplates?.data?.length ? (
        <>
          <FormItemDivider label={'Application method documentation'} marginTop={30} />
          {company?.attributes?.documentTemplates?.data?.length && company?.attributes?.documentTemplates?.data?.map(it => (
            <ImageFileCard key={it?.id} name={it?.attributes?.name || 'image-name'} imageUrl={it?.attributes?.url} isBordered />
          ))}
        </>
      ) : null}
      <FormItemDivider label={'Employees'} marginTop={30} />
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {employees?.length ? employees?.map(it => <UserContactCard key={it?.id} user={it} />) :
          <TablePlaceholder imageSize={'small'} title={''} text={'No employees in current company'} />}
      </Space>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Close'}
          btnType={'primary'}
          onClick={onCancel}
          loading={loading}
        />
      </Row>
    </>
  )
}
export default CompanyInfo
