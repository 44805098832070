import BlackNotification from '@/components/blackNotification/BlackNotification'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { lineLayerProps } from '@/components/google-map/helpers/lineLayersProps'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import ProvincesSelection from '@/components/google-map/provinces-selection/ProvincesSelection'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useAreaQuery, useProvincesQuery, useUpdateAreaMutation } from '@/graphql'
import { Form, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, Ref, useEffect, useRef, useState } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const ChangeGeometryProvince: FC = () => {
  const [form] = useForm()
  const mapRef = useRef<MapRef>()

  const { setIsModalOpen, currentId, setModalTopOffset, setModalWidth } = useGeneralContext()
  const [selectedProvincesIds, setSelectedProvincesIds] = useState<string[]>([])
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)


  const [updateArea, { loading: areaUpdating }] = useUpdateAreaMutation()
  const { data: provincesData } = useProvincesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const provinces = provincesData?.provinces?.data as ProvinceEntity[]
  const provincesOptions = provinces?.map(it => ({
    label: it?.attributes?.name?.replace('.kml', ''),
    value: it?.id,
  })) as SelectOptionsType[]

  const { data, loading } = useAreaQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentArea = data?.area?.data as AreaEntity

  useEffect(() => {
    setSelectedProvincesIds(currentArea?.attributes?.provinces?.data?.map(it => it?.id as string) || [])
  }, [currentArea?.attributes?.provinces?.data])

  const initialValues = {
    provinces: currentArea?.attributes?.provinces?.data?.map(it => it?.id),
  }
  const onProvinceChange = (idsArray: string[]) => {
    idsArray && setSelectedProvincesIds(idsArray)
    setIsSaveDisabled(false)
  }

  const onSave = async () => {
    await updateArea({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          provinces: selectedProvincesIds,
        },
      },
      onError: (error) => {
        console.log(error)
      },
      onCompleted: () => {
        BlackNotification('Geometry provinces updated successfully.')
        setIsModalOpen(false)
        setModalTopOffset(null)
        setModalWidth(null)
      },
      refetchQueries: 'active',
    })
  }
  return (
    <>
      <FormItemDivider
        title={'Change province'}
        subTitle={'The Geometry province determines that the geometry will be processed  only within selected provinces while creating an application.'}
      />
        <div className={'big-modal-with-scroll-container'}>
          <Form form={form} layout={'vertical'} autoComplete={'off'} initialValues={initialValues}>
            <FormItem
              name={'provinces'}
              type={'multi-select'}
              label={'Provinces'}
              selectOptions={provincesOptions}
              handleMultiChange={onProvinceChange}
              placeholder={'Select Provinces'}
              loading={loading}
            />
          </Form>
          <MapBoxMap
            isAreasCreating={areaUpdating}
            isSaving={areaUpdating}
            onDelete={() => null}
            onUpdate={() => null}
            mapRef={mapRef as Ref<MapRef>}
            mapContainerHeight={500}
            isSaveButtonShow={true}
            isDrawTools={false}
            isGeometryVisibilityToggle={false}
            onSave={onSave}
            isSaveLoading={areaUpdating}
            isSaveDisabled={isSaveDisabled}
            isLoading={loading}
          >
            <ProvincesSelection provinces={provinces} selectedProvincesIds={selectedProvincesIds} />
            <Source
              key={currentArea?.id}
              id={currentArea?.id as string}
              type={'geojson'}
              data={currentArea?.attributes?.geoJson?.data?.attributes?.url}

            >
              <Layer
                {...lineLayerProps}
                id={currentArea?.id as string}
                paint={{
                  'line-width': 2,
                  'line-color': '#d93232',
                }}
              />
            </Source>
          </MapBoxMap>
        </div>

    </>
  )
}
export default ChangeGeometryProvince
