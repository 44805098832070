import { CSSProperties, FC } from 'react'
import Typography, { EnhancedParagraphProps } from '../typography/Typography'

const { Paragraph } = Typography
type Props = {
  text: Maybe<string>,
  rows?: number
  ellipsisLength?: number
  maxWidth?: number
style?: CSSProperties
}
const EllipsisText:FC<Props & Partial<EnhancedParagraphProps>> = ({style, text, rows = 3, ellipsisLength = 100, maxWidth, ...restProps}) => {
  return (
    <Paragraph
      {...restProps}
      font={'regular'}
      style={{ marginBottom: 0, maxWidth: maxWidth ?? 200, ...style }}
      ellipsis={(Number(text?.length) > ellipsisLength) ? {
        rows,
        suffix: '',
      } : false}
    >
      {text}
    </Paragraph>
  )
}

export default EllipsisText
