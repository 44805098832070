import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import { useCreateContactRequestMutation } from '@/graphql'
import { Col, Form, FormInstance, Input, Row, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC } from 'react'
import '../../../../../../styles/website-form-elements.less'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'

const { Text } = WebsiteTypography

const ContactForm: FC = () => {
  const [form] = Form.useForm()
  const [createContactRequest, {loading}] = useCreateContactRequestMutation()
  const onFinish = async (values: Partial<ContactRequestInput>) => {
    await createContactRequest({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: values,
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        message.success('Request sent successfully')
        form.resetFields()
      },
    })
  }

  return (
    <Form<Partial<ContactRequestInput>>
      onFinish={onFinish}
      className={'website-form'}
      autoComplete={'off'}
      form={form as FormInstance<ContactRequestInput>}
    >
      <Row gutter={24} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item
            className={'website-form-item'}
            name={'firstName'}
            rules={[{ required: true, message: 'Please, enter your First Name' }]}
          >
            <Input placeholder={'First Name'} className={'website-input'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className={'website-form-item'}
            name={'lastName'}
            rules={[{ required: true, message: 'Please, enter your Last Name' }]}
          >
            <Input placeholder={'Last Name'} className={'website-input'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item
            className={'website-form-item'}
            name={'email'}
            rules={[
              { type: 'email', message: 'Please input a valid email!' },
              { required: true, message: 'Please, enter your email' },
            ]}
          >
            <Input placeholder={'Email'} className={'website-input'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className={'website-form-item'} name={'phoneNumber'}>
            <Input placeholder={'Phone Number'} className={'website-input'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ border: '1px solid' }}>
          <Form.Item
            className={'website-form-item contact-message'}
            name={'message'}
          >
            <TextArea placeholder={'Write your message here...'} rows={5} className={'website-input'} />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: 30 }} justify={'center'}>
        <WebsiteButton htmlType={'submit'} loading={loading}>
          <Text size={'md'}>{'SUBMIT NOW'}</Text>
        </WebsiteButton>
      </Row>
    </Form>
  )
}

export default ContactForm
