import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import GetFreeQuoteButton from '@/components/website/get-free-quote-button/GetFreeQuoteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { useOrganizationsQuery } from '@/graphql'
import VideoPlayer from '@/pages/website-landing/components/video-player/VideoPlayer'
import { websiteRoutes } from '@/router/routes'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Col, Row, Skeleton } from 'antd'

import { FC } from 'react'
import { useParams } from 'react-router'
import './HomeHero.less'
import background from './images/hero-bg.webp'
import hexagonBg from './images/hero-hexagon-bg.png'
import videoReg from '../../../components/video-player/video-materials/tour-video.mp4'
import videoScrn from '../../../components/video-player/video-materials/images/for_loading-img.png'

const { Title, Paragraph } = WebsiteTypography

type Props = {
  content?: ComponentUiCard
  loading: boolean
}
const HomeHero: FC<Props> = ({ content, loading }) => {
  const titleArray = content?.title?.split(' ')
  const middleTitleString = titleArray?.slice(1, -1).join(' ')
  const params = useParams()
  const currentOrgSlug = params.org

  const { data, loading: orgLoading } = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: { slug: { eqi: currentOrgSlug } },
    },
  })
  const currentOrganization = data?.organizations?.data?.[0]

  const fontColor = currentOrgSlug === EASETECH_COMPANY ? 'white' : currentOrganization?.attributes?.fontColor ? currentOrganization?.attributes?.fontColor : 'white'

  return (
    <>
      {currentOrgSlug === EASETECH_COMPANY && (
        <Row className={'hero-video-wrapper'} justify={'center'}>
          {/*<Col span={16}>*/}
          <Col className={'hero-video-col'}>
            <VideoPlayer videoUrl={videoReg} screenshotUrl={videoScrn}/>
          </Col>
        </Row>
      )}


      <div className={'home-hero'}>
        <div className={'container'}>
          <div className={'hero-content-wrapper'}>
            <div className={'hero-content'}>
              {loading || orgLoading ? (
                <div className={'title-wrapper'}>
                  <Skeleton paragraph={{ rows: 8 }} active />
                </div>
              ) : (
                <div className={'title-wrapper'}>
                  <Title level={1} color={'white'} className={'hero-title'} style={{ color: fontColor }}>
                    {currentOrgSlug === EASETECH_COMPANY ? (
                      <>
                        <span className={'bold'}>{titleArray?.[0]}</span>
                        <span>{` ${middleTitleString} `}</span>
                        <span className={'bold'}>{titleArray?.[titleArray?.length - 1]}</span>
                      </>
                    ) : (
                      <span className={'bold'}>{currentOrganization?.attributes?.heroSection?.title}</span>
                    )}

                  </Title>
                  <Paragraph size={'lg'} color={'white'} weight={'w700'} className={'hero-text'} style={{ color: fontColor }}>
                    {currentOrgSlug === EASETECH_COMPANY ? content?.subtitle : currentOrganization?.attributes?.heroSection?.subtitle}
                  </Paragraph>
                </div>
              )}
              {currentOrgSlug === EASETECH_COMPANY && (
                <div className={'btn-wrapper'}>
                  <GetFreeQuoteButton type={'orange'} />
                </div>
              )}
            </div>
          </div>
        </div>


        <div
          className={`bg-image-wrapper ${
            currentOrgSlug !== EASETECH_COMPANY ? 'bg-image-wrapper-full' : ''
          }`}
        >
          {currentOrgSlug === EASETECH_COMPANY ? (
            <img src={background} alt={''} />
          ) : (
            <img
              style={{ height: '100%' }}
              src={currentOrganization?.attributes?.heroSection?.media?.data?.attributes?.url}
              alt={''}
            />
          )}
        </div>

        <div className={'bg-image-hexagon-wrapper'}>
          {currentOrgSlug === EASETECH_COMPANY && (
            <img src={hexagonBg} alt={''}/>
          )}
        </div>


      </div>
    </>
  )
}

export default HomeHero










