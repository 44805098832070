import { Col, Row } from 'antd';
import { FC, ReactNode} from 'react'

import './SectionLayout.less';

type SectionLayoutProps = {
  backgroundColor?: 'primary-color' | 'primary-dark-blue' | 'blue-accent' | 'second-primary-color';
  showImageBackground?: boolean;
  imageClass?: string;
  bgImageUrl?: string;
  mirrored?: boolean;
  children: ReactNode;
};

const SectionLayout: FC<SectionLayoutProps> = (
  {
    backgroundColor = 'blue-accent',
    showImageBackground = false,
    imageClass,
    bgImageUrl,
    mirrored = false,
    children,
  }
) => {

  return (
    <Row
      className={`section-large-layout section-large-layout-${backgroundColor}`}
    >

      {showImageBackground && (
        <div
          className={imageClass ? imageClass : 'default-bg-img-large-layout'}
        >
          <img src={bgImageUrl} alt={'background'} />
        </div>
      )}

      <Col offset={mirrored ? 2 : 3} span={19}>
        {children}
      </Col>
    </Row>
  );
};

export default SectionLayout;