import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import Status from '@/components/status/Status'
import { useUpdateApplicationMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { Form, Row, Skeleton, Space, Spin } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const statusSelectOptions = [
  {
    label: <Status status={'active'} />,
    value: 'active',
  },
  {
    label: <Status status={'pending'} />,
    value: 'pending',
  },
  {
    label: <Status status={'rejected'} />,
    value: 'rejected',
  },
  {
    label: <Status status={'stopped'} />,
    value: 'stopped',
  },
]

const { Title } = Typography
const ChangeAgentStatus: FC = () => {
  const [updateApplication, { loading: updatingApplication }] = useUpdateApplicationMutation()

  const { currentId, setIsModalOpen } = useGeneralContext()

  const { data, loading } = useApplicationByUuid()
  const currentApplication = data?.applications?.data?.[0] as ApplicationEntity
  const currentCard = currentApplication?.attributes?.agentsInfo?.find(it => it?.agentProfile?.data?.id === currentId) as ComponentDataAgentInfo

  const onFinish = async (value: { status: EnumComponentdataagentinfoAssignstatus }) => {
    const changedInfo = currentApplication?.attributes?.agentsInfo?.map(it => {
      if (it?.agentProfile?.data?.id === currentId) {
        return {
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: value?.status,
        }
      } else {
        return {
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: it?.assignStatus,
        }
      }

    }) || []

    const newAgentsInfo = [...changedInfo]
    currentApplication?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentApplication?.id,
        data: {
          agentsInfo: newAgentsInfo,
        },
      },
      onCompleted: () => {
        BlackNotification('Status updated successfully')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error changing status. ${error?.message || ''}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'all',
    })
  }
  const initialValues = {
    status: currentCard?.assignStatus || 'pending',
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <FormItemDivider title={'Change status'} subTitle={'Changing you status will change your level of access to a particular applications.'} marginTop={0} />
      {loading ? (
        <Skeleton paragraph={{rows: 3}} active={true} />
      ) : (
        <>
          <SelectUserItem isBordered={true} name={currentCard?.agentProfile?.data?.attributes?.fullName} email={currentCard?.agentProfile?.data?.attributes?.primaryEmail} avatar={currentCard?.agentProfile?.data?.attributes?.avatar?.data?.attributes?.url} />
        <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues} style={{marginTop: 24}}>
          <FormItem
            name={'status'}
            type={'select'}
            label={'Assignment status'}
            placeholder={'Select assignment status'}
            selectOptions={statusSelectOptions as SelectOptionsType[]}
          />
          <Row justify={'end'}>
            <Space>
              <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} loading={updatingApplication} />
              <Button text={'Confirm selection'} btnType={'primary'} htmlType={'submit'} loading={updatingApplication} />
            </Space>
          </Row>
        </Form>
        </>
      )}
    </>
  )
}
export default ChangeAgentStatus
