import WebsiteButton from '@/components/website-button/WebsiteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { defaultPrimaryColor } from '@/utilities/global-variables'
import { FC } from 'react'

const { Text } = WebsiteTypography

type Props = {
  type?: 'orange' | 'darkBlue'
}

declare global {
  interface Window {
    Calendly: any;
  }
}

const GetFreeQuoteButton: FC<Props> = ({type = 'orange'}) => {

  const onGetQuote = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/easetech-info/30min?background_color=0f2745&text_color=ffffff&primary_color=ff6600',
      });
    } else {
      console.error('Calendly script is not loaded');
    }
  };

  return (
    <WebsiteButton onClick={onGetQuote} style={{backgroundColor: type === 'darkBlue' ? defaultPrimaryColor : '', zIndex: 3}}>
      <Text size={'md'}>{'BOOK A DEMO'}</Text>
    </WebsiteButton>
  )
}

export default GetFreeQuoteButton
