import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import './VideoPlayer.less'

type VideoComponentProps = {
  videoUrl?: string;
  screenshotUrl: string;
  className?: string;
  playing?: boolean;
  paddingSize?: 'small' | 'middle' | 'large';
};

const VideoPlayer: React.FC<VideoComponentProps> = ({ videoUrl, screenshotUrl, playing, paddingSize = 'middle' }) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const [isVideoReady, setIsVideoReady] = useState(false)

  useEffect(() => {
    if (playing===false){setIsPlaying(false)}
  }, [playing])

  const togglePlayPause = () => {
    setIsPlaying((prevState) => !prevState)
  }

  const handleReady = () => {
    setIsVideoReady(true)
  }

  const paddingClass = `padding-${paddingSize}`

    return (
      <div className={`video-wrapper ${paddingClass}`} onClick={togglePlayPause}>

        {!isVideoReady && (
          <div className={'screenshot-placeholder'}>
            <img src={screenshotUrl} alt={'image of future video-tour'} />
          </div>
        )}

        {!isPlaying && (
          <div className={'play-orange-button'}>
            <div className={'triangle'}></div>
          </div>
        )}

        <ReactPlayer
          url={videoUrl}
          onReady={handleReady}
          playing={isPlaying}
          muted
          loop
          controls={false}
          className={`tour-player`}
          width={'100%'}
          height={'100%'}
          playsinline={true}
        />
      </div>
    )
  // }

}

export default VideoPlayer
