import { UploadProps } from 'antd'
import { getAuthToken } from '@/helpers/auth'
import { DocExtensions, GeometryExtensions, ImageExtensions } from '@/types/upload'

export const getUploadProps = (
  acceptedExtension?: DocExtensions | GeometryExtensions |ImageExtensions | string,
  multiple = true,
  showUploadList = true,
  maxCount: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 = 10
): UploadProps => {
  const acceptedExt: ImageExtensions | DocExtensions | GeometryExtensions | undefined = acceptedExtension ?? undefined
  return (
    {
      name: 'files',
      listType: 'picture',
      action: `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
      headers: { Authorization: `Bearer ${getAuthToken()}` },
      accept: acceptedExt,
      maxCount,
      multiple,
      showUploadList,
    } as UploadProps
  )
}