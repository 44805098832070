import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import { getMapSnapshot } from '@/components/google-map/helpers/getMapSnapshot'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCreateKickoffRecordMutation, useUpdateKickoffRecordMutation } from '@/graphql'
import { Point } from 'geojson'
import moment from 'moment'
import { MutableRefObject, useCallback, useState } from 'react'
import { MapRef } from 'react-map-gl'

interface UseSaveAsDraftProps {
  mapRef: MutableRefObject<MapRef | undefined>
  appData: ApplicationEntity;
  location: Point | null;
}

const useSaveRecordAsDraft = ({ mapRef, appData, location }: UseSaveAsDraftProps) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [createRecord] = useCreateKickoffRecordMutation()
  const [updateRecord] = useUpdateKickoffRecordMutation()

  const onSaveAsDraft = useCallback(
    async (
      values: KickoffRecordInput,
      uploadFilesIds: Maybe<string>[] | undefined,
      uploadApprovalFilesIds: Maybe<string>[] | undefined,
      itemId: Maybe<string>,
      selectedInviteId: Maybe<string>,
      participants: Maybe<string>[],
      status: EnumKickoffrecordStatus
    ) => {
      try {
        setLoading(true)

        const mapSnapshot = await getMapSnapshot(mapRef) as unknown as (UploadFileInput & { id: string })[]



        if (itemId) {
          const updateRes =  await updateRecord({
            fetchPolicy: globalFetchPolicy,
            variables: {
              id: itemId,
              data: {
                date: moment(values?.date).format('YYYY-MM-DD'),
                time: moment(values?.time).format('HH:mm:ss.SSS'),
                description: values?.description,
                discussionTopics: values?.discussionTopics,
                status,
                application: appData?.id,
                location,
                participants,
                mapSnapshot: mapSnapshot?.[0]?.id,
                photos: uploadFilesIds,
                approvalDocuments: uploadApprovalFilesIds,
                owner: user?.userProfile?.data?.id,
                kickoffInvite: selectedInviteId ? selectedInviteId : null
              },
            },
            refetchQueries: 'active',
            onCompleted: () => {
              BlackNotification('Record updated successfully')
            },
            onError: error => {
              BlackNotification(`Record update failed. ${error.message}`)
            },
          })
          return updateRes?.data?.updateKickoffRecord?.data?.id

        } else {
          const result = await createRecord({
            fetchPolicy: globalFetchPolicy,
            variables: {
              input: {
                date: moment(values?.date).format('YYYY-MM-DD'),
                time: moment(values?.time).format('HH:mm:ss.SSS'),
                description: values?.description,
                discussionTopics: values?.discussionTopics,
                status,
                application: appData?.id,
                location,
                participants,
                mapSnapshot: mapSnapshot?.[0]?.id,
                photos: uploadFilesIds,
                approvalDocuments: uploadApprovalFilesIds,
                owner: user?.userProfile?.data?.id,
                kickoffInvite: selectedInviteId ? selectedInviteId : null
              },
            },
            refetchQueries: 'active',
            onCompleted: () => {
              BlackNotification('Record created')
            },
            onError: error => {
              BlackNotification(`Record creation failed. ${error.message}`)
            },
          })
          return result?.data?.createKickoffRecord?.data?.id
        }
      } catch (error) {
        console.error('Error saving as draft:', error)
        return null
      } finally {
        setLoading(false)
      }
    },
    [appData?.id, createRecord, location, mapRef, updateRecord, user?.userProfile?.data?.id],
  )

  return { onSaveAsDraft, loading }
}

export default useSaveRecordAsDraft
