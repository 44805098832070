import { useMemo } from 'react'

const useApprovalSetUserProfileIds = (appData?: ApplicationEntity): string[] => {
  return useMemo(() => {
    return (
      appData?.attributes?.approvalSet?.reduce<string[]>((accum, it) => {
        if (it?.isSiteVisitRequired) {
          const contacts = it?.contacts?.data?.map(contact => contact.id || '') || [];
          return accum.concat(contacts);
        }
        return accum;
      }, []) || []
    );
  }, [appData]);
};

export default useApprovalSetUserProfileIds;